.rep-container{ 
    background-color: var(--background);
    width: 100vw;
    height:  100%;
    margin-top: 3px;
    margin-bottom: -3px;
}
.rep-section{
    padding: 5% 17%
}
.rep-section h1{
    padding: 0 8%;
    font-family: Roboto;
    font-size: 54px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: var(--tertiary-1);
}
.rep-section h2{
    font-family: Roboto;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--tertiary-1);
}
.rep-section h3{
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.rep-section Button{
    display: block;
    margin: 0 auto;
    padding: 17px 100px;
}

.rep-section1{
    margin:100px 0px;
}
.rep-section10{
    display: flex;
    flex-direction: row;
    flex: 1;
}
.rep-box{
    flex: 1;
    border-radius: 10px;
    margin-right: 20px;
    padding: 0 10px;
}
.rep-box h6{
    margin-bottom: 5px;
    padding: 0px 15px ;
    font-family: Roboto;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f4f0e3;
}
.rep-box p{
    padding: 0px 20px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #ffffff;
}

.rep-section2{
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 80px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rep-section2 h1{
    font-family: Roboto;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
    text-align: start;
}
.rep-section2 p{
    margin-left: 50px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: start;
    color: var(--black-050);
}
.rep-section2 a{
    font-weight: 500;
    color: var(--secondary-1);
}
.rep-icon-0 {
    background-image: url(../../assets/icons/statis.png);
    padding: 110px;
    background-position: center center;
    background-size: 100% ;
    background-repeat : no-repeat;
    border-style: none;
    margin: 5%;
}

.rep-section3{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 80px;
    flex:1
}
.rep-section30{
    flex: 1;
    min-width: 200px;
    padding: 40px 15px 15px 15px;
    margin: 10px;
    border-radius: 8px;
    background-color: #ffffff;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--black-0);
}
.rep-section30 p{
    margin-top: -10px;
    padding: 0 18px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.rep-section30 h4{
    padding: 0 5px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--tertiary-1);
}
.rep-icon-1{
    background-image: url(../../assets/icons/utils.png);
    display: block;
    margin: 0 auto;
    padding: 40px 45px;
    background-size: 100% 100%;
    background-repeat : no-repeat;
    border-style: none;
}
.rep-icon-2{
    background-image: url(../../assets/icons/timer.png);
    display: block;
    margin: 0 auto;
    padding: 40px 45px;
    background-size: 100% 100%;
    background-repeat : no-repeat;
    border-style: none;
}
.rep-icon-3{
    background-image: url(../../assets/icons/okCircle.png);
    display: block;
    margin: 0 auto;
    padding: 40px 50px;
    background-size: 100% 100%;
    background-repeat : no-repeat;
    border-style: none;
}
.rep-section4{
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 50px;
    border-radius: 8px;
    background-color: var(--tertiary-1);
}
.rep-section4 h1{
    text-align: start;
    font-family: Roboto;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f4f0e3;
}
.rep-section40{
    flex: 1;
    padding: 15px;
    margin: 10px;
    display: block;
    display: flex;
    flex-direction: row;
}
.rep-section40 div{
    margin: 0 25px
}
.rep-section40 h2{
    text-align: start;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.rep-section40 p{
    opacity: 0.7;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #ffffff;
}

.rep-section5{
    padding: 0 20px;
    border-radius: 8px;
    margin-bottom: 50px;
    background-color: var(--tertiary-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rep-section5 h1{
    padding-top: 30px;
    text-align: start;
    font-family: Roboto;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f4f0e3;
}
.rep-section5 p{
    margin-left: 50px;
    opacity: 0.7;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #ffffff;
}
.rep-icon-4 {
    background-image: url(../../assets/icons/discussion.png);
    padding: 130px;
    background-position: center center;
    background-size: 100% ;
    background-repeat : no-repeat;
    border-style: none;
    margin: 8%;
}
.rep-section6{
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 80px;
    background-color: #ffffff;
    justify-content: start;
    align-items: flex-start;
}
.rep-section6 h1{
    margin-left: -30px ;
    font-family: Roboto;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
    text-align: start;
    width: 50%;
}
.rep-section60{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 30px;
}
.circle-option{
    width: 40px;
    height: 40px;
    min-height:40px;
    min-width:40px;
    display: inline-block;
    border-radius: 20px;
    margin: 10px;
    background-color: var(--tertiary-2);
    justify-content: center;
    text-align: center;
}
.circle-option h2{
    margin-top: 10px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.rep-section6 p{
    margin-left: 10px;
    margin-right: 30%;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
}
.rep-section6 a{
    font-weight: 500;
    color: var(--secondary-1);
    cursor: pointer
}


@media (max-width: 900px){
    .rep-section{
        padding: 5% 5%
    }
    .rep-section Button{
        margin-bottom: 50px;
    }
    .rep-section h1{
        padding: 0 5%;
        font-size: 43px;
    }
    .rep-section h2{
        font-size: 35px;
    }
    .rep-section h3{
        font-size: 21px;
    }
    .rep-section1{
        margin: 0%;
    }
    .rep-section10{
        flex-wrap: wrap;
    }
    .rep-box{
        padding-bottom: 10px;
        margin: 0 10px 20px 10px;
    }
    .rep-section3{
        flex-wrap: wrap;
    }
    .rep-section2{
        margin-top: 40px;
        flex-wrap: wrap;
    }
    .rep-section2 p{
        margin-left: 25px;
    }
    .rep-icon-0 {
        display: block;
        margin: 0 auto;
        padding: 120px;
    }

    .rep-section4{
        padding: 10px;
        margin-bottom: 40px;
    }
    .rep-section40{
        padding: 10px;
        margin: 10px;
        flex-wrap: wrap;
    }
    .rep-section40 div{
        margin: 0 10px
    }
    .rep-section40 h2{
        font-size: 21px;
    }
    .rep-section5{
        padding: 20px 10px;
        margin-bottom: 40px;
        flex-wrap: wrap;
    }
    .rep-section5 h1{
        padding-top: 20px;
        font-size: 40px;
    }
    .rep-section5 p{
        margin-left: 20px;
    }
    .rep-icon-4 {
        display: block;
        margin: 0 auto;
        padding: 120px;
    }
    .rep-section6{
        padding: 20px;
        margin-bottom: 60px;
    }
    .rep-section6 h1{
        margin-left: 10px;
        margin-right: 20px;
        font-size: 20px;
        width: 70%;
    }
    .rep-section60{
        margin-left: 0px;
    }
    .circle-option h2{
        font-size: 20px;
    }
    .rep-section6 p{
        margin-right: 10px;
    }
}


@media (max-width: 500px){
    .rep-section h1{
        padding: 0 10px;
        font-size: 35px;
    }
    .rep-section Button{
        padding: 17px 20%;
    }
    .rep-box{
        padding-bottom: 10px;
    }
    .rep-section30{
        min-width: 50%;
    }
    .rep-section2{
        padding: 10px 0;
    }
    .rep-section2 p{
        margin-left: 10px;
    }

    .rep-section h3{
        font-size: 18px;
    }
    
}

@media (max-width: 300px){
    .rep-box{
        margin: 10px 0px;
        padding: 0px;
    }
    .rep-icon-0 {
        padding: 40%;
        margin: 5px;
        align-self: center;
    }
}