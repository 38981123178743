.entreprise-background{
    display: flex;
    flex-direction: column;
}
.entreprise-block-1{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 15%;
    margin-right: 15%;
    align-items: center;
}
.entreprise-block-1 h1{   
    font-family: Roboto;
    font-size: 52px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: var(--black-075);
    text-align: center;
}
.entreprise-block-1 h1 span{   
    color: var(--tertiary-1);
}
.entreprise-block-1 p{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-075);
    text-align: center; 
    margin-top: -10px;
}
.entreprise-outlineButton-container{
    margin-left: 10%;
    width: fit-content;
}
.entreprise-outlineButton{
    margin-top: 50px;
    padding: 0px 34px; 
    border: 1px solid var(--tertiary-1);
    border-radius: 8px;
    cursor: pointer;
}
.entreprise-outlineButton:hover{
    opacity: 0.6;
}
.entreprise-outlineButton:active{
    opacity: 0.6;
    transform: scale(0.97);
}
.entreprise-outlineButton p{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--tertiary-1);
    margin-top: 12px;
}
.entreprise-car-img{
    margin-top: 50px;
    background-image: url(../../assets/images/Entreprise_image.png);
    height: 70vh;
    background-size: cover;
}
.entreprise-car-img-suite{
    height: 8.351vw;
    background-image: url(../../assets/images/bg-up.svg);
    background-size: cover;
    background-repeat : no-repeat;
}
.entreprise-gradient-btn{
    height: 40px;
    width: fit-content;
    background: linear-gradient(270deg, var(--secondary-1) 0.15%, var(--main) 100%);
    padding-left: 17px;
    padding-right: 18px;
    border-radius: 27px;
}
.entreprise-gradient-btn p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    margin-top: 10px;
}
.entreprise-block-2{
    margin-top: 80px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    align-items: center;
}
.entreprise-block-2 h2{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 37px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: var(--black-075);
    flex-wrap: wrap;
}
.entreprise-block-2 h2 span{   
    color: var(--tertiary-1);
    margin-left: 10px;
    margin-right: 8px;
}
.entreprise-block-21{
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.entreprise-block-211{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 30%;
}
.entreprise-block-211 p{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--black-075);
    text-align: center;
}
.entreprise-block-3{
    margin-bottom: 300px;
    padding-left: 10%;
    padding-right: 10%;
}
.entreprise-block-30{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--tertiary-1);
    justify-content: space-between;
    border-radius: 8px;
    max-width: 100vw;
    flex-wrap: wrap;
}
.entreprise-block-33{
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 20px;
}
.entreprise-block-33 h2{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 40px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
}
.entreprise-block-33 p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    flex-wrap: wrap;
    max-width: 400px;
}
.entreprise-block-3-circle{
    margin-top: 20px;
    margin-right: 80px;
    width: 360px;
    height: 300px;
    background-size: cover;
    background-repeat : no-repeat;
    background-image: url(../../assets/images/Pattern.png);
}
.entreprise-phone-icon{
    position: absolute;
    width: 250px;
    height: 500px;
    background-size: cover;
    background-repeat : no-repeat;
    background-image: url(../../assets/images/Broken_Phone.png);
    margin-top: 50px;
    margin-left: 60px;
}
.entreprise-block-4{
    margin-top: 70px;
    width: 100vw;
    margin-bottom: 150px;
    position: relative;
}
.entreprise-background-color{
    position: absolute;
    width: 100vw;
    height: 420px;
    background-color: var(--background2);
    top: 0;
    z-index: -1;
}
.entreprise-block-41{
    padding-top: 60px;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-direction: row;
}
.entreprise-block-411{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: 60%;
}
.entreprise-block-411 h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 64px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-87);
}
.entreprise-block-4-icon{
    width: 250px;
    height: 500px;
    background-size: cover;
    background-repeat : no-repeat;
    background-image: url(../../assets/images/mock-2.png);
}
.entreprise-block-412{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.entreprise-block-4121{
    display: flex;
    flex-direction: row;
    background-color: var(--tertiary-1);
    padding-right: 20px;
    align-items: center;
}
.entreprise-block-4122{
    display: flex;
    flex-direction: row;
    background-color: var(--tertiary-3);
    padding-right: 20px;
    margin-bottom: 1px;
    align-items: center;
}
.entreprise-block-41211{
    padding: 50px;
    justify-content: center;
    align-items: center;
}
.entreprise-block-41212{
    display: flex;
    flex-direction: column
}
.entreprise-block-4121 h2{
    font-family: Roboto;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
}
.entreprise-block-4121 p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF
}
.entreprise-block-4122 h2{
    font-family: Roboto;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px; 
    letter-spacing: 1px;
    text-transform: uppercase;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-87);;
}
.entreprise-block-4122 p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-87);
}
.entreprise-block-5{
    width: 100vw;
    background-color: var(--tertiary-1);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    padding-top: 40px;
}
.entreprise-block-5-suite{
    height: 8.351vw;
    background-image: url(../../assets/images/suite2.svg);
    background-size: cover;
    background-repeat : no-repeat;
}
.entreprise-block-5 h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    line-height: 80px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    width: 55%;
}
.entreprise-solidButton{
    margin-top: 50px;
    padding: 0px 32px; 
    background-color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 0;
}
.entreprise-solidButton:hover{
    opacity: 0.8;
}
.entreprise-solidButton:active{
    opacity: 0.7;
    transform: scale(0.98);
}
.entreprise-solidButton p{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--tertiary-1)
}
.entreprise-block-6{
    padding: 80px;
    background: var(--background3)
}
.entreprise-block-62{
    width: 60%;
    display: flex;
    flex-direction: row; 
}
.entreprise-block-62 p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-87);
}
.entreprise-block-62 span{
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 98px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #2EC5CE;
    margin-right: 20px;
    margin-top: -11px;
}
.entreprise-block-61{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.entreprise-block-6-pic{
    width: 56px;
    height: 56px;
    background-size: cover;
    background-repeat : no-repeat;
    background-image: url(../../assets/images/Picture.png);
    margin-right: 17px;
}
.entreprise-block-6 h3{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-87);
    margin-bottom: -17px;
    margin-top: 2px;
}
.entreprise-block-6 h4{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-060);
}
.entreprise-block-611{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
}
.entreprise-block-6111{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.entreprise-block-6-suite{
    height: 8.351vw;
    background-image: url(../../assets/images/bg-down.svg);
    background-size: cover;
    background-repeat : no-repeat;
}
.entreprise-block-7{
    padding: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.entreprise-block-71{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.entreprise-block-71 h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 65px;
    color: var(--black-87);
}
.entreprise-sepLine{
    width: 100px;
    height: 3px;
    left: 130px;
    top: 356px;
    background: linear-gradient(270deg, var(--secondary-1) 0.15%, var(--main) 100%);
    border-radius: 10px;
    margin-bottom: 40px;
}
.entreprise-block-7-icon{
    height: 128px;
    width: 120px;
    background-image: url(../../assets/images/comment.png);
    background-size: 100%;
    background-repeat : no-repeat;
}
.entreprise-block-72{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 50%;
    margin-left: 50px;
}
.entreprise-block-72 h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: #000000;
}
.entreprise-block-72 h6{
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #7F7F7F;
    margin-top: 15px;
}
.entreprise-block-72 em{
    font-weight: normal;
    font-size: 13px;
    color: red;
}
@media (max-width: 1150px){
    .entreprise-block-3{
        padding-left: 8%;
        padding-right: 8%;
    }
    .entreprise-block-33{
        padding-left: 50px;
        padding-right: 5px;
    }
    .entreprise-block-3-circle{
        margin-right: 20px;
    }
    .entreprise-block-411 h1{
        font-size: 40px;
        line-height: 50px;
    }
    .entreprise-block-41{
        padding-left: 8%;
        padding-right: 8%;
    }
}
@media (max-width: 1000px){
    .entreprise-block-3{
        padding-left: 5%;
        padding-right: 5%;
    }
    .entreprise-block-33{
        padding-left: 40px;
        padding-right: 0px;
    }
    .entreprise-block-3-circle{
        margin-right: 10px;
    }
    .entreprise-block-1{
        margin-left: 10%;
        margin-right: 10%;
    }
    .entreprise-block-2{
        margin-left: 6%;
        margin-right: 6%;
    }
    .entreprise-block-411 h1{
        font-size: 35px;
        line-height: 45px;
    }
    .entreprise-block-41{
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media (max-width: 900px){
    .entreprise-car-img-suite,
    .entreprise-car-img{
        display: none;
    }
    .entreprise-car-img-mobile{
        margin-top: 50px;
        background-image: url(../../assets/images/Entreprise_image.png);
        height: 60vw;
        background-size:contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .entreprise-block-1{
        background: #FFFFFF;
        box-shadow: 0px 2px 80px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        margin: -100px 5% 0;
        padding: 35px 5%;
    }
    .entreprise-block-30{
        justify-content: center;
        border-radius: 8px;
    }
    .entreprise-block-3{
        padding-left: 15%;
        padding-right: 15%;
        margin-bottom: 80px;
    }
    .entreprise-block-33{
        padding-top: 50px;
        padding-bottom: 10px;
        padding-left: 13%;
        padding-right: 0px;
    }
    .entreprise-block-33 p{
        max-width: 80%;
    }
    .entreprise-block-3-circle{
        margin-right: 0px;
        margin-bottom: 10px;
        width: 360px;
        height: 330px;
    }
    .entreprise-block-41211{
        padding: 8%;
    }
    .entreprise-block-5 h1{
        font-size: 48px;
        line-height: 70px;
        width: 80%;
    }
    .entreprise-block-6{
        padding: 3%;
    }
    .entreprise-block-62{
        width: 70%;
    }
    .entreprise-block-7{
        padding: 6%;
    }
    .entreprise-block-71{
        width: 90%;
    }
    .entreprise-block-71 h1{
        font-size: 36px;
        line-height: 55px;
    }
    .entreprise-block-72{
        width: 90%;
        margin-left: 15px;
    }
    .entreprise-outlineButton-container{
        margin-left: 0;
        margin-top: 210px;
        display: flex;
        justify-content: center;
        width: auto;
    }
}
@media (max-width: 700px){
    .entreprise-block-41{
        flex-wrap: wrap;
    }
    .entreprise-block-411{
        width: 100%;
        align-items: center;
    }
    .entreprise-block-411 h1{
        text-align: center;
    }
    .entreprise-block-4-icon{
        margin-bottom: 50px;
    }
    .entreprise-block-2 h2{
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        margin-left: 8%;
    }
    .entreprise-block-2 h2 span{
        margin-left: 30%;
    }
    .entreprise-block-21{
        margin-top: 30px;
        justify-content: center;
    }
    .entreprise-block-211{
        width: 50%;
    }
    .entreprise-block-1 h1{  
        font-size: 45px;
    }
    .entreprise-block-211{
        width: 70%;
    }
    .entreprise-block-211 p{
        font-size: 21px;
        line-height: 30px;
    }
    .entreprise-block-5 h1{
        font-size: 45px;
        line-height: 60px;
    }
}
@media (max-width: 500px){
    .entreprise-block-1{
        margin-top: -70px ;
    }
    .entreprise-block-1 h1{  
        font-size: 36px;
    }
    .entreprise-block-1{
        margin-left: 20px;
        margin-right: 20px;
    }
    .entreprise-block-1 p{
        font-size: 16px;
        line-height: 30px;
    }
    .entreprise-outlineButton{
        margin-top: 30px;
        padding: 0px 20px; 
    }
    .entreprise-outlineButton p{
        font-size: 15px;
        line-height: 15px;
        margin-top: 12px;
    }
    .entreprise-block-2 h2{
        font-size: 26px;
        line-height: 40px;
        margin-left: 7%;
    }
    .entreprise-block-2 h2 span{
        margin-left: 35%;
    }
    .entreprise-block-21{
        margin-top: 30px;
        justify-content: center;
    }
    .entreprise-block-211{
        width: 90%;
    }
    .entreprise-block-211 p{
        font-size: 17px;
        line-height: 27px;
    }
    .entreprise-block-30{
        justify-content: center;
        border-radius: 8px;
    }
    .entreprise-block-3{
        padding-left: 0;
        padding-right: 0;
    }
    .entreprise-block-33{
        padding-top: 40px;
        padding-bottom: 10px;
        padding-left: 10%;
    }
    .entreprise-block-33 p{
        max-width: 100%;
    }
    .entreprise-block-5 h1{
        font-size: 35px;
        width: 95%;
    }
    .entreprise-solidButton{
        padding: 0px 20px; 
    }
    .entreprise-solidButton p{
        font-size: 14px;
        line-height: 15px;
    }
    .entreprise-block-61{
        flex-wrap: wrap;
    }
    .entreprise-block-62{
        width: 100%;
    }
    .entreprise-block-6111{
        margin-top: 20px;
    }
    .entreprise-block-6 h3{
        font-size: 15px;
    }
    .entreprise-block-6 h4{
        font-size: 13px;
        line-height: 20px;
    }
    .entreprise-block-62 p{
        font-size: 15px;
        line-height: 28px;
    }
    .entreprise-block-62 span{
        font-size: 60px;
        line-height: 80px;
        margin-right: 14px;
    }
    .entreprise-block-7{
        padding: 15px;
        flex-wrap: wrap;
    }
    .entreprise-block-71{
        align-items: center;
        width: 100%;
    }
    .entreprise-block-71 h1{
        text-align: center;
        font-size: 27px;
        line-height: 48px;
    }
    .entreprise-block-72{
        width: 90%;
        margin-left: 15px;
    }
    .entreprise-block-72 h5{
        font-size: 16px;
    }
    .entreprise-block-72 h6{
        font-size: 13px;
        line-height: 120%;
    }
}
@media (max-width: 350px){
    .entreprise-block-3-circle{
        width: 300px;
        height: 300px;
    }
    .entreprise-phone-icon{
        position: absolute;
        width: 180px;
        height: 360px;
        margin-top: 40px;
        margin-left: 60px;
    }
    .entreprise-block-411{
        margin-right: 0px;
        width: 100%;
        align-items: center;
    }
    .entreprise-block-411 h1{
        text-align: center;
        font-size: 28px;
    }
    .entreprise-block-4-icon{
        margin-bottom: 50px;
        }
    .entreprise-block-4121{
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .entreprise-block-4122{
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .entreprise-block-41211{
        padding: 30px;
    }
    .entreprise-block-4121 h2{
        text-align: center;
        font-size: 14px;
        line-height: 16px;
    }
    .entreprise-block-4121 p{
        text-align: center;
        font-size: 13px;
        line-height: 22px;
    }
    .entreprise-block-4122 h2{
        text-align: center;
        font-size: 14px;
        line-height: 16px;
    }
    .entreprise-block-4122 p{
        text-align: center;
        font-size: 13px;
        line-height: 22px;
    }
    .entreprise-block-5 h1{
        font-size: 28px;
        line-height: 30px;
        width: 98%
    }
    .entreprise-outlineButton-container{
        margin-top: 100px;
    }
}
@media (max-width: 290px){
    .entreprise-block-3-circle{
        width: 250px;
        height: 250px;
    }
    .entreprise-phone-icon{
        margin-left: 33px;
    }
}
@media (max-width: 250px){
    .entreprise-block-3-circle{
        width: 200px;
        height: 200px;
    }
    .entreprise-phone-icon{
        width: 130px;
        height: 260px;
        margin-left: 33px;
    }
    .entreprise-block-4-icon{
        width: 130px;
        height: 260px;
    }
}