
.background-image-mission {  
    /* The image used */
    background-image: url(../../assets/images/image-img-hero-mission.png);
    /*opacity: 0.3;*/
    background-position: center bottom;
    background-repeat : no-repeat;
    width: 100vw;
    height: 100%;
    padding-top: 1px;
    background-color: var(--background);
    margin-top: 3px;
}

.mission{
    padding: 4.5% 40% 60px 10%;
}
.small-mission{
    padding: 10% 5%;
}
.mission h1, .mission-section-1 h1{
    font-family: Roboto;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black-080);
}
.mission p , .mission-section-1 p{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
    padding-right: 11%;
    padding-bottom: 10px;
}

.small-mission h1{
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black-080);
}
.small-mission p{
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
    padding-right: 5%;
    padding-bottom: 10px;
}
.mission-section-1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10% 15%
}
.mission-car {
    background-image: url(../../assets/icons/car.png);
    padding:200px;
    background-position: center center;
    background-size: 100%;
    background-repeat : no-repeat;
    border-style: none;
    margin-right: 30px;
    margin-bottom: 30px;
}
.mission-section-2{
    justify-content: justify;
    margin: 10% 25%
}
.mission-section-2 h1{
    text-align: justify;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-070);
}
.mission-section-2 h2{
    margin: 0 21% 40px 19%;
    text-align:center;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.mission-section-2 p{
    text-align: justify;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.mission-section-2 a{
    color: var(--secondary-1);
}

@media (max-width: 900px){
    .gm-mission{
        padding-top: 20px;
    }
    .mission-section-1{
        margin: 5% 10%; 
    }
    .mission-car {
        padding:30%;
        margin-right: 30px;
        margin-bottom: 20px;
    }
    .mission-section-1{
        text-align: center;
        flex-wrap: wrap;
    }
    .mission-section-1 p{ 
        padding-right: 0%;
    }
    .mission-section-2{
        justify-content: justify;
        margin: 10%;
        padding-bottom: 20px;
    }
    .mission-section-2 h2{
        margin: 2%;
        padding-bottom: 20px;
    } 
}