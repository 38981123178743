.homeV2-details-container{
    padding: 0 9%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 60px;
    align-items: center;
}
.homeV2-details-block{
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    padding: 0 7vw;
}   
.homeV2-details-image-1{
    width: 30vw;
    height: 15vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.homeV2-details-image-2{
    width: 28vw;
    height: 26vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.homeV2-details-image-3{
    width: 30vw;
    height: 27vw;
    margin-top: -50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.homeV2-details-block h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 62px;
    color: #2D2D2D;
}
.homeV2-details-block p{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #2C2C2C;
    opacity: 0.75;
    text-align: left;
}
.homeV2-details-image-mobile{
    display: none;
}
@media (max-width: 900px){
    .homeV2-details-image-1,
    .homeV2-details-image-2,
    .homeV2-details-image-3{
        display: none;
    }
    .homeV2-details-image-mobile{
        display: flex;
        height: 40vw;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .homeV2-details-container{
        padding: 20px;
        gap: 50px;
        justify-content: center;
    }
    .homeV2-details-block{
        flex-direction: column;
        gap: 0px;
        padding: 0;
    } 
    .homeV2-details-block h1{
        text-align: center;
    }
    .homeV2-details-block p{
        text-align: center;
    }
}
@media (max-width: 500px){
    .homeV2-details-block h1{
        font-size: 28px;
        line-height: 42px;
    }
    .homeV2-details-block p{
        font-size: 20px;
        line-height: 26px;
    }
}
@media (max-width: 420px){
    .homeV2-details-container{
        padding: 0px;
    }
}