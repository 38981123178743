.homeV2-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 8%;
}
.homeV2-header-block h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #2E2F32;
    text-align: left;
}
.homeV2-header-block p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2E2F32;
    text-align: left;
}
.homeV2-rating-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.fixing-rating-margin{
    margin-left: -60px;
    margin-bottom: 20px;
}
.homeV2-header-image div{
    width: 50vw;
    height: 50vw;
    background-size: cover;
    background-repeat: no-repeat;
}
.homeV2-header-image-mobile{ display: none;}
@media (max-width: 1240px){
    .homeV2-header-image div{
        width: 60vw;
        height: 60vw;
        background-size: cover;
    }
}
@media (max-width: 1180px){
    .homeV2-header-block h1{
        font-size: 58px;
    }
}
@media (max-width: 900px){
    .homeV2-header-image{ display: none;}
    .homeV2-header-container{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }
    .homeV2-header-image-mobile{
        display: flex;
        width: 100vw;
        height: 50vw;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .homeV2-header-block{
        background: #FFFFFF;
        box-shadow: 0px 2px 80px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        margin: -50px 5% 0;
        padding: 35px 5%;
    }
    .homeV2-header-block h1{
        text-align: center;
    }
    .homeV2-header-block p{ display: none;}
    .homeV2-rating-container{
        flex-direction: column;
    }
    .fixing-rating-margin{
        margin-left: -90px;
        margin-bottom: 0;
    }
}
@media (max-width: 600px){
    .homeV2-header-block h1{
        font-size: 40px;
        line-height: 44px;
    }
}
@media (max-width: 500px){
    .homeV2-header-block{
        margin: -40px 20px 0;
        padding: 5px;
    }
}