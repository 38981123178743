.page_application_reparateur {
  max-width: 100vw;
  overflow-x: hidden;
}
.page_application_reparateur .cta_button {
  padding: 15px 50px;
  background: #ed4f56;
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
}
.page_application_reparateur .cta_button i {
  color: #fff;
}
.page_application_reparateur * {
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.page_application_reparateur .container {
  width: 1400px;
  margin: 0 auto;
}
@media (max-width: 1399px) {
  .page_application_reparateur .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page_application_reparateur section.hero .container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.hero .container {
    flex-direction: column;
    align-items: center;
  }
}
.page_application_reparateur section.hero .container .col_1,
.page_application_reparateur section.hero .container .col_2 {
  width: 50%;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.hero .container .col_1,
.page_application_reparateur section.hero .container .col_2 {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .page_application_reparateur section.hero .container .col_1 {
    margin-bottom: 60px;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 {
    margin-bottom: 0;
  }
}
.page_application_reparateur section.hero .container .col_1 .test_appareils_pannes {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */
  /* Content/ body */
  color: #383a47;
  mix-blend-mode: normal;
  opacity: 0.8;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .test_appareils_pannes {
    font-size: 16px;
    line-height: 130%;
    text-align: center;
  }
}
.page_application_reparateur section.hero .container .col_1 .main_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 130%;
  /* or 73px */
  /* Content/ header */
  color: #1b1c20;
  margin: 30px 0 50px 0;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .main_title {
    font-size: 40px;
    line-height: 130%;
    /* or 52px */
    text-align: center;
    margin: 30px 0 20px 0;
  }
}
.page_application_reparateur section.hero .container .col_1 .main_image_mobile {
  display: none;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .main_image_mobile {
    display: block;
    width: 100%;
  }
}
.page_application_reparateur section.hero .container .col_1 .test_gratuit {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */
  /* Content/ body */
  color: #383a47;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .test_gratuit {
    font-size: 15.5px;
    line-height: 130%;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .center {
    display: flex;
    justify-content: center;
  }
}
.page_application_reparateur section.hero .container .col_1 .stores {
  display: flex;
  margin-bottom: 60px;
  margin-top: 50px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .stores {
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .stores img {
    width: 150px;
  }
}
.page_application_reparateur section.hero .container .col_1 .stores img:first-child {
  margin-right: 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .stores img:first-child {
    margin-right: 10px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review {
  display: flex;
  align-items: center;
}
.page_application_reparateur section.hero .container .col_1 .review .main_icon {
  margin-right: 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .review .main_icon {
    margin-right: 10px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review .text {
  margin: 0 10px;
}
.page_application_reparateur section.hero .container .col_1 .review .text .first {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 30px;
  line-height: 42px;
  /* identical to box height, or 140% */
  letter-spacing: -1px;
  /* Content/ header */
  color: #1b1c20;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .review .text .first {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review .text .second {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  /* Content/ body */
  color: #383a47;
  mix-blend-mode: normal;
  opacity: 0.6;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .review .text .second {
    font-size: 16px;
    line-height: 24px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review .separator {
  height: 40px;
  width: 2px;
  background-color: #1b1c20;
  margin: 0 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .review .separator {
    margin: 0 10px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review .note_stars {
  margin: 0 10px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .review .note_stars {
    margin: 0 5px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review .note_stars .first {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  line-height: 42px;
  /* identical to box height, or 140% */
  letter-spacing: -1px;
  /* Content/ header */
  color: #1b1c20;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_1 .review .note_stars .first {
    font-size: 24px;
    line-height: 28px;
  }
}
.page_application_reparateur section.hero .container .col_1 .review .note_stars .stars .fas {
  color: #ffc947;
}
.page_application_reparateur section.hero .container .col_1 .review .note_stars .stars .far {
  color: #5b7486;
  opacity: 0.3;
}
.page_application_reparateur section.hero .container .col_2 img {
  width: 100%;
}
@media (max-width: 500px) {
  .page_application_reparateur section.hero .container .col_2 img {
    display: none;
  }
}
.page_application_reparateur section.utilite_app_neero {
  margin: 50px 0;
}
.page_application_reparateur section.utilite_app_neero .container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.utilite_app_neero .container {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container {
    flex-direction: column-reverse;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_1 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_application_reparateur section.utilite_app_neero .container .col_1 img {
  width: 55%;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.utilite_app_neero .container .col_1 {
    width: 100%;
    margin-bottom: 50px;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 {
  width: 60%;
  padding-left: 50px;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 {
    width: 100%;
    padding-left: 0;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 .yes_we_can {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  letter-spacing: 0.16em;
  color: #ed4f56;
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .yes_we_can {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 .main_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */
  color: #000000;
  margin: 20px 0 50px 0;
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .main_title {
    font-size: 36px;
    line-height: 130%;
    text-align: center;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 .second_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  /* identical to box height, or 100% */
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .second_title {
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    line-height: 28px;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 .second_title h3 {
  color: #000;
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .second_title h3 {
    text-align: center;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 .second_title img {
  margin-right: 10px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .second_title img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.page_application_reparateur section.utilite_app_neero .container .col_2 .description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  color: #000000;
  opacity: 0.5;
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .description {
    font-size: 14px;
    line-height: 28px;
    /* or 200% */
    text-align: center;
    margin-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.utilite_app_neero .container .col_2 .center {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
}
.page_application_reparateur section.etapes_reservation {
  margin: 80px 0;
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation {
    margin: 0 0 50px 0;
  }
}
.page_application_reparateur section.etapes_reservation .container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.etapes_reservation .container {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation .container {
    flex-direction: column;
  }
}
.page_application_reparateur section.etapes_reservation .container .col_1 ul {
  list-style: none;
}
.page_application_reparateur section.etapes_reservation .container .col_1 ul li {
  margin: 50px 0;
}
.page_application_reparateur section.etapes_reservation .container .col_1 ul li header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation .container .col_1 ul li header {
    flex-direction: column;
    align-items: center;
  }
}
.page_application_reparateur section.etapes_reservation .container .col_1 ul li header img {
  margin-right: 10px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation .container .col_1 ul li header img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.page_application_reparateur section.etapes_reservation .container .col_1 ul li header h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  /* identical to box height, or 100% */
  color: #000000;
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation .container .col_1 ul li header h3 {
    font-size: 24px;
    line-height: 28px;
  }
}
.page_application_reparateur section.etapes_reservation .container .col_1 ul li .description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  color: #000000;
  opacity: 0.5;
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation .container .col_1 ul li .description {
    text-align: center;
    font-size: 14px;
    line-height: 150%;
  }
}
.page_application_reparateur section.etapes_reservation .container .col_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.page_application_reparateur section.etapes_reservation .container .col_2 .ombre_violette {
  position: absolute;
  top: -110px;
  right: -100px;
  z-index: -1;
}
@media (max-width: 500px) {
  .page_application_reparateur section.etapes_reservation .container .col_2 .ombre_violette {
    top: -30px;
    right: -60px;
  }
}
.page_application_reparateur section.etapes_reservation .container .col_2 .main_image {
  width: 100%;
}
.page_application_reparateur section.etapes_reservation .container .col_1,
.page_application_reparateur section.etapes_reservation .container .col_2 {
  width: 50%;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.etapes_reservation .container .col_1,
.page_application_reparateur section.etapes_reservation .container .col_2 {
    width: 100%;
  }
}
.page_application_reparateur section.guidage_duree_diagnostic .container .desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  letter-spacing: 0.16em;
  color: #ed4f56;
  margin-bottom: 20px;
  text-align: center;
}
@media (max-width: 500px) {
  .page_application_reparateur section.guidage_duree_diagnostic .container .desc {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
}
.page_application_reparateur section.guidage_duree_diagnostic .container .main_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.guidage_duree_diagnostic .container .main_title {
    font-size: 36px;
    line-height: 130%;
    /* or 47px */
    text-align: center;
    margin-bottom: 10px;
  }
}
.page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic {
  padding-bottom: 50px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic {
    margin-top: 30px;
  }
}
.page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic .swiper-container {
  padding-bottom: 30px;
}
.page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
}
.page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  /* Btn-color-primary */
  background: #ed4f56;
}
.page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic .swiper-slide {
  display: flex;
  justify-content: center;
}
.page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic .swiper-slide .swiper_slide_image {
  width: 250px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.guidage_duree_diagnostic .container .slider_steps_diagnostic .swiper-slide .swiper_slide_image {
    width: 60%;
  }
}
.page_application_reparateur section.guidage_duree_diagnostic .container .center {
  display: flex;
  justify-content: center;
}
.page_application_reparateur section.guidage_duree_diagnostic .container .center .link_reserver_reparation {
  background: linear-gradient(270deg, #00afd0 0.15%, #416fb4 100%);
  border-radius: 50px;
  padding: 15px 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}
.page_application_reparateur section.guidage_duree_diagnostic .container .center .link_reserver_reparation i {
  color: #fff;
}
.page_application_reparateur section.compatibilite {
  margin: 0 0 50px 0;
  position: relative;
}
.page_application_reparateur section.compatibilite .forme_violette {
  position: absolute;
  top: -10px;
  z-index: -1;
  left: -20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .forme_violette.desktop {
    display: none;
  }
}
.page_application_reparateur section.compatibilite .container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.compatibilite .container {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container {
    flex-direction: column;
  }
}
.page_application_reparateur section.compatibilite .container .col_1 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_application_reparateur section.compatibilite .container .col_1 img {
  width: 55%;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.compatibilite .container .col_1 {
    width: 100%;
    margin-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_1 {
    margin-bottom: 0;
  }
  .page_application_reparateur section.compatibilite .container .col_1 img {
    display: none;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 {
  width: 60%;
  padding-left: 50px;
}
@media (max-width: 1000px) {
  .page_application_reparateur section.compatibilite .container .col_2 {
    width: 100%;
    padding-left: 0;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .yes_we_can {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  letter-spacing: 0.16em;
  color: #ed4f56;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 .yes_we_can {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .main_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */
  color: #000000;
  margin: 20px 0;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 .main_title {
    font-size: 36px;
    line-height: 130%;
    /* or 47px */
    text-align: center;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .main_image_mobile {
  width: 100%;
  display: none;
  position: relative;
}
.page_application_reparateur section.compatibilite .container .col_2 .main_image_mobile .main {
  width: 100%;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 .main_image_mobile .main {
    width: 55%;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .main_image_mobile .forme_violette {
  position: absolute;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 .main_image_mobile {
    display: flex;
    justify-content: center;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .liste {
  list-style: none;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 4rem;
       column-gap: 4rem;
  row-gap: 2rem;
  margin-bottom: 50px;
  margin-top: 50px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 .liste {
    grid-template-columns: repeat(1, 1fr);
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .liste li {
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 .liste li {
    align-items: center;
    width: 100%;
    padding-left: 30px;
  }
}
.page_application_reparateur section.compatibilite .container .col_2 .liste li.last {
  margin: 20px 0 0 0;
}
.page_application_reparateur section.compatibilite .container .col_2 .liste li p {
  padding-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  color: #000000;
}
.page_application_reparateur section.compatibilite .container .col_2 .liste li:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
  color: #4bd37b;
}
.page_application_reparateur section.compatibilite .container .col_2 footer.compta {
  display: flex;
  align-items: center;
}
.page_application_reparateur section.compatibilite .container .col_2 footer.compta .description {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  color: #000000;
  margin-right: 20px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.compatibilite .container .col_2 footer.compta .description {
    font-size: 14px;
    line-height: 28px;
    /* or 200% */
    text-align: center;
  }
}
.page_application_reparateur section.tester_notre_app {
  margin: 50px 0;
  width: 99%;
}
.page_application_reparateur section.tester_notre_app .container {
  display: flex;
  align-items: center;
  background: #ed4f56;
  border-radius: 20px;
  padding: 50px 60px;
  position: relative;
}
@media (max-width: 1400px) {
  .page_application_reparateur section.tester_notre_app .container {
    padding: 30px 40px;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container {
    padding: 50px 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
  }
}
.page_application_reparateur section.tester_notre_app .container .elipses_right_top {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .elipses_right_top {
    display: none;
  }
}
.page_application_reparateur section.tester_notre_app .container .elipses_left_bottom {
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .elipses_left_bottom {
    display: none;
  }
}
.page_application_reparateur section.tester_notre_app .container .elipses_mobile {
  display: none;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .elipses_mobile {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -40px;
    display: block;
  }
}
.page_application_reparateur section.tester_notre_app .container .col_1 {
  position: relative;
  z-index: 2;
  width: 75%;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_1 {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_1 .center {
    display: none;
  }
}
.page_application_reparateur section.tester_notre_app .container .col_1 .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 130%;
  /* or 83px */
  color: #ffffff;
  margin-bottom: 0px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_1 .title {
    font-size: 36px;
    line-height: 130%;
    text-align: center;
    margin-bottom: 30px;
  }
}
.page_application_reparateur section.tester_notre_app .container .col_1 .cta_button {
  margin: 30px 0;
  background-color: #fff;
  color: #ed4f56;
}
.page_application_reparateur section.tester_notre_app .container .col_1 .cta_button i {
  color: #ed4f56;
}
.page_application_reparateur section.tester_notre_app .container .col_1 .download_app {
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_1 .download_app {
    margin-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_1 .download_app img {
    width: 160px;
  }
}
.page_application_reparateur section.tester_notre_app .container .col_1 .download_app .app_store {
  margin-right: 20px;
}
.page_application_reparateur section.tester_notre_app .container .col_2 {
  display: flex;
  justify-content: flex-end;
  width: 25%;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_2 {
    justify-content: center;
    width: 100%;
  }
}
.page_application_reparateur section.tester_notre_app .container .col_2 img {
  position: relative;
  z-index: 2;
  width: 100%;
}
@media (max-width: 500px) {
  .page_application_reparateur section.tester_notre_app .container .col_2 img {
    width: 80%;
  }
}
.page_application_reparateur section.section_contact {
  margin: 50px 0 0 0;
}
.page_application_reparateur section.section_contact .center {
  display: flex;
  justify-content: center;
}
.page_application_reparateur section.section_contact .center img {
  width: 100%;
}
@media (max-width: 500px) {
  .page_application_reparateur section.section_contact .center img {
    width: 100%;
  }
}
.page_application_reparateur section.section_contact .container .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  letter-spacing: 0.16em;
  text-align: center;
  color: #ed4f56;
  padding-bottom: 10px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.section_contact .container .title {
    font-size: 16px;
    line-height: 28px;
  }
}
.page_application_reparateur section.section_contact .container .second_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .page_application_reparateur section.section_contact .container .second_title {
    font-size: 40px;
    line-height: 130%;
  }
}
.page_application_reparateur section.section_contact .container .ctas {
  display: flex;
  justify-content: center;
}
@media (max-width: 500px) {
  .page_application_reparateur section.section_contact .container .ctas {
    flex-direction: column;
    align-items: center;
  }
}
.page_application_reparateur section.section_contact .container .ctas .cta {
  display: inline-block;
  padding: 10px 100px;
  border-radius: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  /* identical to box height */
  text-transform: uppercase;
  margin: 0 10px;
  color: #ed4f56;
  border: 1px solid #ed4f56;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: all ease 0.3s;
}
@media (max-width: 500px) {
  .page_application_reparateur section.section_contact .container .ctas .cta {
    padding: 10px 20px;
    width: 90%;
    display: block;
    margin: 10px 0;
  }
}
.page_application_reparateur section.section_contact .container .ctas .cta:hover {
  background-color: #ed4f56;
  color: #fff;
}