body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  background-color: #fff;  
  height: 100%;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-overflow-scrolling: none,
}

.btn-gradient{
  background-image: linear-gradient(257deg, var(--secondary-1) 0%, var(--main)  100%);
  padding: 14px 10px;
  border-radius: 8px;
  border: 0px;
  box-shadow: 0px 1px 1px 1px var(--black-050);
  outline: none;
  cursor: pointer;
}
.btn-gradient:hover{
  opacity: 0.9;
}
.btn-gradient u {  
    font-family:Roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: none;
    letter-spacing: normal;
 }
.btn-gradient:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.10);
} 

.btn-gradient-2{
  background-image: linear-gradient(257deg, var(--secondary-1) 0%, var(--main)  100%);
  padding: 16px 50px;
  border-radius: 59px;
  border: 0px;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.btn-gradient-2 u {  
  margin-right: 10px;
  font-family:Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-decoration: none;
  letter-spacing: normal;
  white-space: nowrap;
}
.btn-gradient-2:hover{
  opacity: 0.9;
}
.btn-gradient-2:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.10);
} 


.btn-cancel{
  background-image: var(--black-010);
  padding: 15px 10px 16px;
  border-radius: 8px;
  border: 0px;
  outline: none;
  cursor: pointer;
}
.btn-cancel u {    
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  letter-spacing: normal;
  color: var(--black-050);
}
.btn-cancel:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.10);
} 

.dialog-title {
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-decoration: none;
  letter-spacing: normal;
  color: var(--tertiary-1);
}
.dialog-text {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.59;
  text-decoration: none;
  letter-spacing: normal;
  color: var(--black-050);
}
@media (max-width: 900px){
  .btn-gradient u {  
      font-size: 14px;
  }
  .btn-gradient-2 u {  
      font-size: 14px;
  }
  .btn-cancel{
    padding: 15px 3px 16px;;
  }
  .btn-cancel u {    
    font-size: 14px;
  }
  .dialog-title {
    font-size: 22px;
  }
  .dialog-text {
    font-size: 16px;
  }

}

@media (max-width: 370px){
  .btn-gradient-2{
    padding: 14px 18px;
  }
  .btn-gradient-2 u{
    flex-wrap: wrap;
  }
}