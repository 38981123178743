.faq-container{ 
    background-color: var(--background);
    width: 100vw;
    height:  100%;
    margin-top: 3px;
    margin-bottom: -3px;
}
.faq{
    padding: 50px 20%;
}
.faq h1{
    font-family: Roboto;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.faq-header{
    flex-wrap: wrap;
}
.faq-header Button {
    margin-top: 0px;
    margin-left: 30px;
    height: 55px;
}
.searchBar{
    background-color: #ffffff;
    width: 50%;
}
.qr-container{
    width: 90%;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 50px 40px 30px 70px;
    margin: 60px 0;
}
.qr-container div{
    padding-bottom: 10px
}
.qr-container h2{
    margin-left: -25px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.qr-container p{
    margin-top: -10px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
    text-align: left;
}


@media (max-width: 1000px){
    .faq{
        padding: 40px 15%;
    }
    .faq h1{
        font-size: 42px;
    }
    .searchBar{
        background-color: #ffffff;
        width: 60%;
    }
    .qr-container{
        width: 85%;
    }
}

@media (max-width: 700px){
    .faq{
        padding: 40px 5%;
    }
    .faq h1{
        font-size: 40px;
    }
    .searchBar{
        background-color: #ffffff;
        width: 70%;
    }
    .qr-container{
        width: 83%;
        padding: 40px 30px 30px 50px;
        margin: 50px 0;
    }
}
@media (max-width: 550px){
    .faq{
        padding: 40px 3%;
    }
    .faq h1{
        font-size: 35px;
    }
    .searchBar{
        background-color: #ffffff;
        width: 90%;
    }
    .faq-header Button {
        margin: 30px 0px;
        height: 50px;
        width: 50%;
    }
    .qr-container{
        width: 83%;
        padding: 30px 20px 20px 40px;
        margin: 40px 0;
    }
    .qr-container h2{
        margin-left: -25px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--tertiary-1);
    }
    .qr-container p{
        margin-top: -10px;
        font-family: Roboto;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.59;
        letter-spacing: normal;
        color: var(--black-050);
    }
}