.EntrepriseBlock2-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    padding: 0 95px;
}
.EntrepriseBlock2-container h1{
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    font-family: 'Roboto';
    color: #2E2F32;
    margin: 0;
    text-align: center;
}
.EntrepriseBlock2-container p{
    font-weight: 400;
    font-size: 20px;    
    line-height: 26px;
    font-family: 'Roboto';
    text-align: center;
    letter-spacing: -0.5px;
    color: #757575
}
.EntrepriseBlock2-blocks{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 30px
}
.EntrepriseBlock2-block {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.EntrepriseBlock2-block h2{
    margin-top: 30px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    font-family: 'Roboto';
    text-align: center;
    color: #3D3D3D;
    width: 80%;
}
.EntrepriseBlock2-block div{
    width: 256px;
    height: 253.88px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
@media (max-width: 1020px){
    .EntrepriseBlock2-block h2{
        width: 100%;
    }
    .EntrepriseBlock2-block div{
        width: 202px;
        height: 200px;
    }
    .EntrepriseBlock2-block {
        width: 100%;
    }
}
@media (max-width: 850px){
    .EntrepriseBlock2-container{
        margin-top: 50px;
        padding: 50px 4%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #DEE3F8;
    }
    .EntrepriseBlock2-blocks{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 30px
    }
}
@media (max-width: 500px){
    .EntrepriseBlock2-container h1{
        font-size: 32px;
        line-height: 36px;
    }
    .EntrepriseBlock2-container p{
        font-size: 18px;
        line-height: 26px;
    }
    .EntrepriseBlock2-block h2{
        font-size: 24px;
        line-height: 36px;
    
    }
    .EntrepriseBlock2-block div{
        margin-top: 40px;
        width: 175px;
        height: 173.55px;
    }
}