.homeV2-repdom-container{
    height: 50vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    padding-left: 9%;
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.homeV2-repdom-block{
    width: 50%;
}
.homeV2-repdom-block h1{
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.5px;
    color: #2C2C2C;
}
.homeV2-repdom-block span{
    color: #722CC8;
}
.homeV2-repdom-block p{
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.5px;
    color: #757575;
    text-align: left;
}
.homeV2-repdom-mobile{display: none;}
@media (max-width: 940px){
    .homeV2-repdom-block h1{
        font-size: 45px;
    }
    .homeV2-repdom-block p{
        font-size: 21px;
    }
}
@media (max-width: 900px){
    .homeV2-repdom-container{
        height: fit-content;
        background: none;
        padding-left: 0;
        padding: 20px;
    }
    .homeV2-repdom-block{
        width: 100%;
    }
    .homeV2-repdom-mobile{
        display: flex;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 55vw;
    }
    .homeV2-repdom-block h1{
        text-align: center;
    }
    .homeV2-repdom-block p{
        text-align: center;
    }
}
@media (max-width: 450px){
    .homeV2-repdom-block h1{
        font-size: 30px;
        line-height: 36px;
    }
    .homeV2-repdom-block p{
        font-size: 20px;
        line-height: 26px;
    }
}