.tunnel-cmd-item{
    justify-content: center;
    align-items: center;
    padding: 5px 9px;
    background: #FFFFFF;
    border: 1px solid rgba(114, 44, 200, 0.25);
    border-radius: 12px;
}

.tunnel-cmd-item p {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.015em;
    margin: 0;
}
.tunnel-cmd-item p span{
    font-size: 12px;
    margin-right: 5px;
}