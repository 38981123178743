.from-register {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 480px;
    padding-left: 0%;
}
.from-register h2{
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.from-register h3{
    padding-top: 10px;
    margin-bottom: 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.from-register h4{
    margin-bottom: 0px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.from-register h4 em{
    color: var(--secondary-1);
}
.from-register h5{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.from-register em{
    margin: 3px 5px 0px 10px;
    font-size: 13px;
    color: #f50057;
}
.from-register .btn-cancel{
    width: 48%;
    margin-right: 4%;
}
.from-register .btn-gradient{
    width: 48%;
}
.from-register p{
    padding-top: 40px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.btn-from-register, .btn-from-register a {
    align-self: center;
    color: var(--secondary-1);
}

.icon-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 40px;
}
.icon-title h2{
    margin:0%;
    padding-left: 15px;
}

.check-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: -20px;
}
.check-box h5{  
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
    margin-top: 10px;
}
.dialog-from-register h1{
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.dialog-from-register h2{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.dialog-from-register p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
}

@media (max-width: 900px){
    .from-register {
        width: 90%;
    }
    .from-register h2{
        padding-bottom: 30px;
        font-size: 22px;
    }
    .from-register h3{
        padding-top: 10px;
        font-size: 19px;
    }
    .from-register h4{
        margin: 0px;
    }

}