.container-interv{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-right: 13%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.interv-row-container{
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content:space-between;
    margin: 3px 15px -3px 15px;
}
.interv-row-container p{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
}
.interv-row-price{
    flex: 1;
    margin-top: 8px;
    flex-direction: row;
    display: flex;
}
.interv-row-container h1{  
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: right;
    color: var(--tertiary-1);
}
.interv-row-container h2{  
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    margin-left: 7px;
    margin-top: 13px;
    font-size: 15px;
    color: var(--tertiary-2);
    text-decoration:line-through;
}
.title-row-container{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin: 0px 10px 3px 10px;
}
.title-row-container p{
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}

.interv-line{
    height: 1px;
    width: 100%;
    opacity: 0.3;
    background-color: var(--black-050);
}
.interv-icon-default{
    height: 34px;
    width: 34px;
    margin-top: 15px;
    -webkit-filter: opacity(.5) drop-shadow(0 0 0 var(--tertiary-1));
    filter: opacity(.5) drop-shadow(0 0 0 var(--tertiary-1));
}
.interv-icon-static{
    height: 36px;
    width: 36px;
    margin-top: 15px;
    margin-right: -5px;
}
.interv-icon{
    height: 28px;
    width: 28px;
    margin-top: 17px;
    -webkit-filter: opacity(.35) drop-shadow(0 0 0 var(--tertiary-2));
    filter: opacity(.35) drop-shadow(0 0 0 var(--tertiary-2));
}


@media (max-width: 900px){
    .container-interv{
        margin-right: 10px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .interv-row-container{
        margin: 2px 10px -2px 10px;
    }
    .interv-row-container p{
        font-size: 16px;
    }
}
@media (max-width: 500px){
    .interv-row-price{
        margin-top: 0px;
        flex-direction: column;
        justify-content: center;
    }
    .interv-row-container h1{  
        font-size: 17px;
        text-align: center;
        margin-bottom: -5px;
    }
    .interv-row-container h2{  
        text-align: center;
        margin-left: 0px;
        margin-top: 0px;
        font-size: 14px;
    }
    .interv-row-container p{
        font-size: 14px;
    }
}
