header.main_header_new_version * {
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header.main_header_new_version .container {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
}
@media (max-width: 1399px) {
  header.main_header_new_version .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1000px) {
  header.main_header_new_version .container .logo img {
    width: 100px;
  }
}
header.main_header_new_version .container .menu_mobile {
  display: none;
}
header.main_header_new_version .container .menu_mobile i {
  font-size: 30px;
}
@media (max-width: 1000px) {
  header.main_header_new_version .container .menu_mobile {
    display: block;
  }
}
header.main_header_new_version .container .navbar {
  display: flex;
  list-style: none;
}
@media (max-width: 1000px) {
  header.main_header_new_version .container .navbar {
    display: none;
  }
}
header.main_header_new_version .container .navbar li {
  margin: 0 20px;
}
header.main_header_new_version .container .navbar li .navitem_link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  font-feature-settings: "salt" on, "liga" off;
  color: #18191f;
  text-decoration: none;
}
@media (max-width: 1000px) {
  header.main_header_new_version .container .cta {
    display: none;
  }
}
header.main_header_new_version .container .cta .cta_btn {
  background: #722cc8;
  border-radius: 8px;
  padding: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
}

.main_drawer_app {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background-color: #722cc8;
  padding: 0px;
  z-index: 99999;
  transition: all ease 0.3s;
}
.main_drawer_app .fa-times {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
  color: #fff;
}
.main_drawer_app .navbar {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-top: 50px;
  margin: 0;
  padding-left: 20px;
}
.main_drawer_app .navbar li {
  margin: 20px 0;
}
.main_drawer_app .navbar li .navitem_link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 143% */
  font-feature-settings: "salt" on, "liga" off;
  color: #fff;
  text-decoration: none;
}