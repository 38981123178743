
.google-rating-container{
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: center;
    width: fit-content;
}
.google-rating-icon{
    width: 90px;
    height: 33px;
    background-repeat : no-repeat;
    background-size: 100% 100%;
    margin-bottom: 3px;
}
.google-rating-star-container{
    display: flex;
    flex-direction: row;
}
.google-rating-star-container div{
    margin-right: 4px;
    height: 10px;
    width: 10px;
    background-size: 100% 100%;
    background-repeat : no-repeat;
}
.google-rating-container h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #F7AD00;
}