.showMoreMox-container{
    background-color: white;
    padding: 20px 25px;
    border-radius: 5px;
}
.showMoreMox-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.showMoreMox-header h3{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0;
}
.showMoreMox-icon{
    cursor: pointer;
}
.showMoreMox-icon:hover{
    opacity: 0.95;
}
.showMoreMox-icon:active{
    transform: scale(0.97);
}
.showMoreMox-content{
    margin-top: 15px;
    margin-right: 40px;
}
.showMoreMox-content p{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(60, 60, 67, 0.85);
    text-align: left;
}