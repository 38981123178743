.EntrepriseBlock1-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.EntrepriseBlock1-title{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
}
.EntrepriseBlock1-title div{
    width: 265px;
    height: 65px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.EntrepriseBlock1-title h1{
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #2E2F32;
    font-family: 'Roboto';
    margin: 0;
    text-align: center;
}
.EntrepriseBlock1-blocks{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 3%
}
.EntrepriseBlock1-block{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.EntrepriseBlock1-block h3{
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    font-family: 'Roboto';
    text-align: center;
    color: #3D3D3D;
    margin: 0;
}
.EntrepriseBlock1-block p{
    font-weight: 600;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #333333;
}
.entreprise-point-number{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 1px solid #722CC8;
    margin-bottom: 40px;
    margin-top: 50px;
}
.entreprise-point-number div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    border-radius: 40px;
    background: #722CC8;
}
.entreprise-point-number h2{
    color: beige;
    font-size: 40px;
}

@media (max-width: 680px){
    .EntrepriseBlock1-block{
        width: 80%;
    }
}
@media (max-width: 500px){
    .EntrepriseBlock1-title div{
        width: 130px;
        height: 30px;
    }
    .EntrepriseBlock1-title h1{
        font-size: 32px;
        line-height: 42px;
    }
    .EntrepriseBlock1-block h3{
        font-size: 32px;
        line-height: 48px;
    }
    .EntrepriseBlock1-block p{
        font-size: 18px;
        line-height: 30px;
    }
}
@media (max-width: 450px){
    .EntrepriseBlock1-block{
        width: 100%;
    }
}