.appDownloadV2-container{
    display: flex;
    flex-direction: row;
    padding-left: 9%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 150px;
}
.appDownloadV2-block h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #2C2C2C;
    text-align: left;
    margin-bottom: 75px;
}
.appDownloadV2-block p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.5px;
    color: #757575;
    text-align: left;
    max-width: 500px;
}
.appDownloadV2-stores{
    margin-top: 50px;
}
.appDownloadV2-image{
    height: 800px;
}
.appDownloadV2-image div{
    position: absolute;
    right: 0;
    width: 580px;
    height: 930px;
}
.appDownloadV2-image-mobile{
    display: none;
}
@media (max-width: 1240px){
    .appDownloadV2-image div{
        position: absolute;
        width: 45vw;
        height: 75vw;
        background-size: cover;
    }
    .appDownloadV2-image{
        height: 65vw;
    }
}
@media (max-width: 1190px){
    .appDownloadV2-image div{
        position: relative;
        width: 45vw;
        height: 75vw;
        background-size: cover;
    }
    .appDownloadV2-image{
        height: 65vw;
    }
}
@media (max-width: 900px){
    .appDownloadV2-container{
        flex-direction: column;
        padding: 70px 9%;
        margin-bottom: 0px;
    }
    .appDownloadV2-block{
        display: flex;
        flex-direction: column;
    }
    .appDownloadV2-block h1{
        text-align: center;
        margin: 0;
    }
    .appDownloadV2-block p{
        max-width: 100%;
        margin-top: 8px;
    }
    .appDownloadV2-image{
        display: none;
    }
    .appDownloadV2-image-mobile{
        display: flex;
        height: 89vw;
        width: 80vw;
        background-size: contain;
    }
    .appDownloadV2-stores{
        margin-top: 20px;
    }
}
@media (max-width: 500px){
    .appDownloadV2-block h1{
        font-size: 30px;
        line-height: 38px;
    }
    .appDownloadV2-block p{
        font-size: 20px;
        line-height: 30px;
    }
}
@media (max-width: 350px){
    .appDownloadV2-image-mobile{
        display: flex;
        height: 100vw;
        width: 90vw;
        background-size: contain;
    }
}