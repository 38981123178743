.typeform-background{
    background-color: var(--background);
    width: 100vw;
    height:  100vh;
    margin-top: 3px;
    padding-bottom: 100px;
}

.typeform{
    margin: 30px 0 0 5%;
    width: 90%;
    height: 90%;
    background: transparent;
    border: 0;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
}