.EntrepriseReviews-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.EntrepriseReviews-gost{
    background-size: contain;
    background-position: left;
    position: absolute;
    background-repeat : no-repeat;
    left: 0;
    width: 350px;
    height: 400px;
    margin-top: -60vw;
}
.EntrepriseReviews-suite{
    height: 8.351vw;
    background-image: url(../../assets/images/bg-up.svg);
    background-size: cover;
    background-repeat : no-repeat;
    
}
.EntrepriseReviews-block{
    background-color: #722CC8;
    width: 100vw;
    margin-left: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
    margin-top: -250px;
}
.EntrepriseReviews-block h1{
    margin-top: 200px;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    font-family: 'Roboto';
    color: #FFFFFF;
    width: 80%;
}

@media (max-width: 1200px){
    .EntrepriseReviews-gost{
        z-index: -1;
        width: 300px;
        height: 350px;
    }
}
@media (max-width: 1200px){
    .EntrepriseReviews-block{
        margin-left: 0
    }
}
@media (max-width: 500px){
    .EntrepriseReviews-gost{
        display: none;
    }
    .EntrepriseReviews-block h1{
        margin-top: 150px;
        font-size: 40px;
        line-height: 55px;
        width: 95%;
    }
}