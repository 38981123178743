footer.footer_main_app {
  background-color: #722cc8;
  padding: 40px 0;
  width: 99%;
}
@media (max-width: 500px) {
  footer.footer_main_app {
    padding: 40px 20px 0 20px;
    width: 91%;
  }
}
footer.footer_main_app * {
  text-align: left;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
footer.footer_main_app .container {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 1399px) {
  footer.footer_main_app .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
footer.footer_main_app .container .logo {
  display: flex;
  justify-content: center;
}
@media (max-width: 500px) {
  footer.footer_main_app .container .logo img {
    width: 150px;
  }
}
footer.footer_main_app .container .row {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
@media (max-width: 1200px) {
  footer.footer_main_app .container .row .col {
    margin-bottom: 30px;
  }
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .col.col_contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  footer.footer_main_app .container .row .col.col_contact .col_title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
  }
  footer.footer_main_app .container .row .col.col_contact .col_title br {
    display: none;
  }
  footer.footer_main_app .container .row .col.col_contact ul li {
    text-align: center;
  }
  footer.footer_main_app .container .row .col.col_contact p {
    text-align: center;
  }
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .col.col_apps {
    width: 100%;
  }
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .col.col_apps .col_title {
    text-align: center;
  }
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .col.col_apps .download_app {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1200px) {
  footer.footer_main_app .container .row {
    flex-wrap: wrap;
  }
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row {
    flex-wrap: wrap;
  }
}
footer.footer_main_app .container .row .col_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 20px;
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .download_app {
    display: flex;
  }
}
footer.footer_main_app .container .row .download_app a {
  text-decoration: none;
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .download_app a {
    margin: 0 5px;
  }
}
footer.footer_main_app .container .row .download_app p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
  display: none;
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .download_app p {
    display: block;
  }
}
footer.footer_main_app .container .row .download_app img {
  display: block;
  margin: 10px 0;
}
footer.footer_main_app .container .row .col_list {
  list-style: none;
}
footer.footer_main_app .container .row .col_list li {
  margin: 15px 0;
}
footer.footer_main_app .container .row .col_list li .social_icons a {
  display: inline-block;
  margin: 0 10px;
}
@media (max-width: 500px) {
  footer.footer_main_app .container .row .col_list li .social_icons a {
    margin: 0 14px;
  }
}
footer.footer_main_app .container .row .col_list li .social_icons a:first-child {
  margin: 0 0;
}
footer.footer_main_app .container .row .col_list li .list_item_link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
}
footer.footer_main_app .container .separator {
  background: #c4c4c4;
  opacity: 0.3;
  height: 2px;
  margin: 20px auto;
  width: 80%;
}
@media (max-width: 500px) {
  footer.footer_main_app .container .separator {
    width: 95%;
  }
}
footer.footer_main_app .container .copyright {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.5;
  text-align: center;
}