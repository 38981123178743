.demande-from-container{
    margin-right: 10px;
    margin-left: 9%;
}
.demande-from-container h1{  
    margin-top: 6%;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-080);
}
.demande-from-container h6{
    font-family: Roboto;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-2);
    margin-top: 5px;
    margin-bottom: 13px;
}

.demande-from-container p{  
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-050);
}
.demande-from-container h5{    
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.btn-gradient{
    margin-top: 30px;
    margin-bottom: 20px;
}
.btn-gradient u{  
    font-size: 15px;
    margin: 0px 10px;
}
.demande-from-container em{
    margin: 3px 5px 0px 10px;
    font-size: 13px;
    color: #f50057;
}
.explic-row{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: 5px;
}
.expli-icon{  
    margin-top: 15px;
    padding: 40px;
    background-position: center center;
    background-size: 100%;
    background-repeat : no-repeat;
    border-style: none;
}
.explic-text{
    margin-left: 20px;
}
.explic-text h1{
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-2);
}
.explic-text p{
    font-family: Roboto;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.just-mobile{
    display: none;
}
.just-mobile p{
    font-family: Roboto;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.just-mobile h2, h3{
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-2);
}
.just-mobile h4{
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.just-mobile u{
    font-family: Roboto;
    font-size: 15px;
    margin-left: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    color: var(--secondary-1);
}
.return-btns{
    display: flex;
    flex-direction: row;
    margin-top: 60px;
}
.dialog-text{
    margin:0;
    margin-left: 30px; 
    text-align: left
}
.model-input{
    width: 69%;
    margin-right: 9px;
}
.ModelsList-container{
    margin-top: 10px;
    max-height: 450px;
    overflow-y: scroll;
}
.dontKnowModel{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EEEBF3;
    border: 1px solid #E5E5E6;
    box-sizing: border-box;
    border-radius: 8px;
    width: 160px;
    padding: 13px;
    padding-top: 25px;
}
.dontKnowModel p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
    color: #722CC8;
}
.dontKnowModel p:active{
    transform: scale(0.97);
}
.dialog-footer-text{
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #2C2C2C;
    text-align: left;
    margin-left: 30px;
    margin-top: 25px;
}
.dialog-footer-text u{
    color: #00AFD0; 
    cursor: pointer;
}
@media (max-width: 900px){
    .ModelsList-container{
        max-height: 380px;
    }
    .gm-input{
        padding-top: 20px;
    }
    .demande-from-container{
        margin-left: 5%;
        margin-bottom: 80px;
    }
    .just-mobile{
        display: contents;
    }
    .dialog-text{
        margin-left: 10px; 
    }
    .dialog-footer-text{
        margin-left: 10px; 
    }
    .model-input{
        width: 350px;
    }
}
@media (max-width: 500px){
    .model-input{
        width: 80%;
    }
    .dialog-text{
        margin-left: 0px; 
    }
    .dialog-footer-text{
        margin-left: 0px;
        font-size: 13px;
    }
    .demande-from-container{
        margin-left: 3%;
    }
    .explic-row{
        flex-direction: column-reverse;
        width: 95%;
        margin-left: 0px;
    }
    .expli-icon{  
        margin-top: 0;
        margin-bottom: 50px;
        padding: 55px;
        background-size: 100% 100%;
    }
    .explic-text{
        margin-left: 10px;
    }
    .explic-text h1{
        font-size: 18px;
    }
    .explic-text p{
        font-size: 15px;
    }
    .return-btns{
        flex-direction: column;
    }
}

@media (max-width: 300px){
    .demande-from-container{
        margin-right: 0;
    }
}