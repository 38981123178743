.section_change_password {
  width: 50%;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.section_change_password .section_change_password_title {
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 30px;
}
.section_change_password form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.section_change_password form .input {
  margin: 0 0 30px 0;
}
.section_change_password form .button_submit {
  margin-top: 30px;
  padding: 10px;
  cursor: pointer;
}

.icon-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.icon-title h2 {
  margin: 0%;
  padding-left: 15px;
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--tertiary-1);
}

@media (max-width: 500px) {
  .section_change_password {
    width: 90%;
    padding: 20px 15px;
  }

  .icon-title {
    align-items: center;
  }

  .icon-title h2 {
    font-size: 20px !important;
  }
}
