.espace-background-register{
    background-color: var(--background);
    width: 100%;
    margin-top: 3px;
    padding-bottom: 50px;
}

.register-card{
    width: 60%;
    height:fit-content;
    margin-left: 10%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--black-0);
}

.register {
    padding: 40px 21% 80px 12%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.register h2{
    padding-bottom: 10px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.register h3{
    padding-top: 30px;
    margin-bottom: 10px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.register h4{
    margin-bottom: 0px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.register h4 em{
    color: var(--secondary-1);
}
.register h5{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.register em{
    margin: 3px 5px 0px 10px;
    font-size: 13px;
    color: #f50057;
}
.register .btn-cancel{
    width: 48%;
    margin-right: 4%;
}
.register .btn-gradient{
    width: 48%;
}
.register p{
    padding-top: 40px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.btn-register, .btn-register a {
    align-self: center;
    color: var(--secondary-1);
}

.icon-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 40px;
}
.icon-title h2{
    margin:0%;
    padding-left: 15px;
}

.check-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.check-box h5{  
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
    margin-top: 10px;
}
.dialog-register h1{
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.dialog-register h2{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.dialog-register p {
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
}
.oblig{
    margin-left: 15px;
    padding-top: -10px;
}

@media (max-width: 900px){
    .register-card{
        width: 94%;
        margin-left: 3%;
        padding-bottom: 20px;
    }
    .register {
        padding: 20px 6%;
    }
    .register h2{
        font-size: 22px;
        margin-bottom: 20px;
    }
    .register h3{
        padding-top: 10px;
        font-size: 19px;
    }
    .register h4{
        margin: 0px;
    }
    .oblig{
        padding-left: 15px;
        padding-top: 15px
    }

}