.diplay-models{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    width: 95%;
}
.diplay-model{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat : no-repeat;
    border: 1px solid #E5E5E6;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}
.diplay-model:hover{
    opacity: 0.8;
}
.diplay-model:active{
    transform: scale(0.99);
}
.diplay-model img{
    width: 160px;
    height: 160px;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat : no-repeat;
}
.diplay-model p{
    margin-top: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #4B566B;
}
@media (max-width: 590px){
    .diplay-models{
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        width: 100%;
    }
    .diplay-model img{
        width: 130px;
        height: 130px;
    }
}