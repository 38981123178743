.homeV2-HomeBrands-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}
.homeV2-HomeBrands-container h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #2E2F32;
    width: 90%;
    text-align: center;
}
.homeV2-HomeBrands-block{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 40px
}
.homeV2-HomeBrands-img{
    width: 200px;
    height: 200px;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 4px 100px 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px
}
.homeV2-HomeBrands-modal{
    margin: 5%;
    background-color: #F4F5F7;
    padding: 5%;
    margin: 5%;
}
.homeV2-HomeBrands-modal-close{
    display: flex;
    justify-content: flex-end;
    margin-top: -2%;
    margin-right: -2%;
}
@media (max-width: 1100px){
    .homeV2-HomeBrands-img{
        width: 150px;
        height: 150px;
    }
}
@media (max-width: 600px){
    .homeV2-HomeBrands-container h1{
        font-size: 30px;
        line-height: 36px
    }
    .homeV2-HomeBrands-block{
        margin: 40px 20px
    }
}
@media (max-width: 380px){
    .homeV2-HomeBrands-img{
        width: 100px;
        height: 100px;
    }
}