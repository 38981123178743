.update_user_infos_section .container {
  width: 1140px;
  margin: 100px auto;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
@media (max-width: 500px) {
  .update_user_infos_section .container {
    width: 95%;
    padding: 20px;
    box-sizing: border-box;
    margin: 50px auto;
  }
}
.update_user_infos_section .container .link_back {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #3498db 0%, #9b59b6 100%);
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
}
.update_user_infos_section .container .title {
  text-align: center;
  margin-bottom: 50px;
  font-weight: normal;
  margin: 0 0 20px 0;
}
.update_user_infos_section .container form {
  width: 100%;
}
.update_user_infos_section .container form .input_container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 500px) {
  .update_user_infos_section .container form .input_container {
    flex-direction: column;
  }
}
.update_user_infos_section .container form .input_container .input {
  display: inline-block;
  width: 49%;
  margin: 10px 0;
}
@media (max-width: 500px) {
  .update_user_infos_section .container form .input_container .input {
    width: 100%;
    margin: 15px 0;
  }
}
.update_user_infos_section .container form .input_container .input div {
  width: 100%;
}
.update_user_infos_section .container form .center.btn_submit_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
@media (max-width: 500px) {
  .update_user_infos_section .container form .center.btn_submit_container {
    margin-top: 10px;
  }
}
.update_user_infos_section .container form .center.btn_submit_container .btn-gradient {
  display: block;
  width: 100%;
}

.icon-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 500px) {
  .icon-title {
    align-items: center;
  }
}

.icon-title h2 {
  margin: 0%;
  padding-left: 15px;
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--tertiary-1);
}
@media (max-width: 500px) {
  .icon-title h2 {
    font-size: 20px !important;
  }
}