.EntrepriseBlock3-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-size: contain;
    width: 100vw;
    height: fit-content;
    margin-top: 80px;
    padding: 50px 0;
    background-image: url(../../assets/images/ent-back.png);
}
.EntrepriseBlock3-block{
    margin-left: 10%;
}
.EntrepriseBlock3-block p{
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    font-family: 'Roboto';
    letter-spacing: -0.5px;
    color: #757575;
    text-align: left;
    width: 80%;
}
.EntrepriseBlock3-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.EntrepriseBlock3-title div{
    background-size: contain;
    width: 64px;
    height: 64px;
}
.EntrepriseBlock3-title h1{
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    font-family: 'Roboto';
    text-align: center;
    letter-spacing: -0.5px;
    color: #2C2C2C;
    text-align: left;
}
.EntrepriseBlock3-image{
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10%;
    width: 350px;
    height: 700px;
    background-position: top;
}
@media (max-width: 1200px){
    .EntrepriseBlock3-container{
        padding-top: 20px;
    }
}
@media (max-width: 1100px){
    .EntrepriseBlock3-container{
        padding-top: 0px;
    }
    .EntrepriseBlock3-block p{
        width: 100%;
    }
}
@media (max-width: 1000px){

    .EntrepriseBlock3-title h1{
        margin: 0;
        margin-top: 20px;
    }
}
@media (max-width: 900px){
    .EntrepriseBlock3-image{ 
        display: none;
    }
    .EntrepriseBlock3-container{
        background-image: none;
    }
    .EntrepriseBlock3-title{
        justify-content: center;
    }
    .EntrepriseBlock3-block p{
        margin: 10px 0;
    }
    .EntrepriseBlock3-block{
        margin:0 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .EntrepriseBlock3-image-mobile{
        margin: 50px 0;
        background-size: contain;
        background-repeat: no-repeat;
        width: 400px;
        height: 335px;
        background-position: center;
    }
}
@media (max-width: 500px){
    .EntrepriseBlock3-title h1{
        font-size: 32px;
        line-height: 38px;
    }
    .EntrepriseBlock3-block p{
        font-size: 20px;
        line-height: 30px;
    }
    .EntrepriseBlock3-title div{
        width: 40px;
        height: 40px;
    }
    .EntrepriseBlock3-block{
        margin:0 20px;
    }
    .EntrepriseBlock3-image-mobile{
        width: 90vw;
        height: 75vw;
    }
}