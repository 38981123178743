.homeV2-HomeEnd-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 9%;
}
.homeV2-HomeEnd-container h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #2E2F32;
    margin: 0;
    text-align: center;
}
.homeV2-HomeEnd-container p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #757575;
}
.homeV2-HomeEnd-block{
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
}
.homeV2-HomeEnd-block div{
    width: 35vw;
    height: 17vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
@media (max-width: 900px){
    .homeV2-HomeEnd-block{
        flex-direction: column;
        gap: 10px;
        justify-content: center;
    }
    .homeV2-HomeEnd-block div{
        width: 50vw;
        height: 23vw;
    }
}
@media (max-width: 600px){
    .homeV2-HomeEnd-container{
        padding: 20px;
    }
    .homeV2-HomeEnd-container h1{
        font-size: 30px;
        line-height: 36px;
        text-align: left;
    }
    .homeV2-HomeEnd-container p{
        text-align: left;
    }
    .homeV2-HomeEnd-block div{
        width: 75vw;
        height: 32vw;
    }
}