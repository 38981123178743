.espace-background {
  background-color: var(--background);
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin-top: 3px;
  padding-bottom: 30px;
}

.espace-background-card {
  padding-left: 15%;
  margin-bottom: 30px;
  align-items: center;
}

.info-name {
  padding: 3% 50% 2% 0%;
}
.info-name h1 {
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--tertiary-1);
}
.info-name p {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  color: var(--black-050);
}

.espace-cards {
  display: flex;
  flex-direction: row;
}

.info-card {
  width: 27%;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--black-0);
  height: 100%;
}
.infos-perso {
  padding: 35px 30px 12px 30px;
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--tertiary-1);
}
.info-card h2 {
  padding-left: 30px;
  margin-bottom: -20px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  color: var(--tertiary-1);
}
.info-card p {
  padding-right: 10px;
  padding-left: 30px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  color: var(--black-050);
}
.card-separator {
  margin: 20px 30px 20px 30px;
  border: 0;
  height: 1px;
  opacity: 0.3;
  background-color: var(--tertiary-2);
}
.espace-cards Button {
  margin-left: 20px;
  margin-bottom: 3px;
  color: var(--secondary-1);
}

.cmd-card {
  width: 50%;
  height: fit-content;
  margin-left: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--black-0);
}
.cmd-null {
  padding-left: 30px;
  padding-bottom: 40px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.59;
  letter-spacing: normal;
  color: var(--tertiary-2);
}

.ss {
  background-color: #000;
  height: 500px;
  width: 100%;
}

@media (max-width: 1250px) {
  .espace-background-card {
    padding-left: 10%;
  }
  .info-card {
    width: 30%;
    margin-left: 2%;
  }
  .info-name {
    padding: 20px 30px;
  }
}
@media (max-width: 1050px) {
  .espace-background-card {
    padding-left: 5%;
  }
  .info-card {
    width: 40%;
    margin-left: 2%;
  }
}

@media (max-width: 970px) {
  .espace-background-card {
    padding-left: 2%;
  }
}

@media (max-width: 900px) {
  .espace-background-card {
    padding-left: 0%;
  }
  .info-name {
    padding: 10px 20px;
  }
  .info-name h1 {
    font-size: 21px;
  }
  .info-name p {
    padding-right: 5px;
    font-size: 16px;
  }
  .espace-cards {
    display: flex;
    flex-direction: column;
  }
  .info-card {
    width: 96%;
    margin-left: 2%;
  }
  .infos-perso {
    padding: 5px 15px;
    font-size: 20px;
  }
  .card-separator {
    margin: 10px 20px;
  }
  .cmd-card {
    margin-top: 30px;
    width: 96%;
    margin-left: 2%;
  }
}

.btn_update_user_infos {
  display: flex;
  text-align: left;
  line-height: 15px;
}
