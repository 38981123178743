.product-container{
    width: 100vw;
}
.product-header-container{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 650px;
    background-image: url(../../assets/images/rep-header.png);
    background-repeat : no-repeat;
    background-size: 100% 100%;
    padding-top: 1px;
    margin-top: 3px;
}
.product-review-container b{
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.5px;
    text-decoration-line: underline;
    color: #757575;
    margin-top: -20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.product-header-image div{
    margin-left: 50px;
    width: 600px;
    height: 630px;
    background-repeat : no-repeat;
    background-size: 100% 100%;
}
.product-header h1{
    margin-top: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    color: #2E2F32;
    margin-bottom: 0;
}
.product-header h1 span{
    color: var(--tertiary-1);
}
.product-header h3{
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 30px;
    color: #2E2F32;
    margin-top: 20px;
    margin-bottom: 40px;
}
.google-rating-container{
    margin-top: 45px;
    margin-left: 110px;
}
.product-engagement-container{
    padding: 5% 7%;
}
.product-engagement-container2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.product-engagement-container h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.5px;
    color: #18191F;
    margin-bottom: -20px;
}
.product-engagement-block{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
}
.pe-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px
}
.pe-item div{
    width: 75px;
    height: 75px;
    background-repeat : no-repeat;
    background-size: 100% 100%;
}
.pe-item p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: #3D3D3D;
    width: 230px;
    text-align: left;
}
.pe-image{
    width: 477px;
    height: 481px;
    background-repeat : no-repeat;
    background-size: 100% 100%;
}
.product-repair-container{
    background-image: url(../../assets/images/pBackground.svg);
    display: flex;
    flex-direction: row;
    padding-top: 160px;
    padding-bottom: 60px;
    gap: 80px
}
.product-repair-image-mobile{
    display: none;
}
.product-repair-image {
    width: 90%;
    height: 550px;
    background-repeat : no-repeat;
    background-size: cover;
    background-position: right
}
.product-repair-block{
    margin-right: 5%;
}
.product-repair-block h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.5px;
    color: #18191F;
}
.product-repair-block p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: #3D3D3D;
    text-align: left;
    width: 80%;
}
.product-review-container{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product-review-container h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #2C2C2C;
    width: 50%;
}
.product-review-scroll-block{
    background: white !important;
    padding-right: 50px !important;
}
.product-review-scroll-item {
    display: inline-block !important;
	width: 30% !important;
	height: 320px !important;
    margin-right: 17px !important;
    margin-left: 17px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.1) !important;
    margin-top: 30px !important;
}
.product-contact-container{
    background-image: url(../../assets/images/pbdImage2.png);
    width: 100vw;
    height: 650px;
    background-repeat : no-repeat;
    background-size: cover;
    padding-top: 100px;
    background-position: center
}
.product-contact-bock{
    background-color: rgb(243, 245, 251, 0.95);
    border-radius: 75px 0px 75px 75px;
    width: 500px;
    padding: 55px;
    margin-left: 50px;
}
.product-contact-bock h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #2C2C2C;
    margin: 0;
}
.product-contact-bock p{
    font-style: normal;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.5px;
    margin-bottom: 60px;
    text-align: left;
    color: #757575;
}
.product-reparations-container{
    width: 100%;
}
.product-reparations-container h1{
    margin-top: 80px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 70px;
    text-align: center;
    color: #2C2C2C;
}
.product-reparations-block{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 50px 20% 70px 20%;
}
.product-reparations-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.product-reparations-item div{
    width: 100px;
    height: 100px;
    background-repeat : no-repeat;
    background-size: 100% 100%;
}
.product-reparations-item p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 129.7%;
    color: #2C2C2C;
    max-width: 130px;
}
.product-reparations-item:hover{
    opacity: 0.85;
}
.product-reparations-item:active{
    opacity: 1;
    transform: scale(0.98);
}
.product-header-mobile{
    display: none;
}
@media (max-width: 1250px){
    .product-engagement-container{
        padding: 4%;
    }
    .product-header h1{
        font-size: 60px;
    }
    .product-repair-block h1{
        font-size: 58px;
    }
    .product-repair-block p{
        font-size: 22px;
    }
    .product-reparations-container h1{
        font-size: 60px;
    }
    .product-review-container h1{
        font-size: 60px;
        width: 55%;
    }
    .pe-image{
        width: 450px;
        height: 452px;
        background-size: contain;
    }
    .product-repair-image {
        height: 500px;
    }
    .pe-image{
        margin-top: 50px;
        background-size: contain;
    }
}
@media (max-width: 1150px){
    .product-header-image div{
        margin-left: 0px;
    }
    .pe-item p{
        font-size: 18px;
    }
    .product-repair-container{
        padding-top: 150px;
        padding-bottom: 45px;
        gap: 50px
    }
    .product-engagement-container{
        padding-right: 30px;
    }
    .product-repair-block{
        margin-right: 30px;
    }
    .product-repair-block p{
        width: 100%;
    }
    .product-review-scroll-item{
        width: 40% !important;
    }
    .product-review-container h1{
        width: 60%;
    }
}
@media (max-width: 1050px){
    .product-engagement-container2{
        padding: 5% 7%;
        flex-direction: column-reverse;
    }
    .product-engagement-block{
        margin-top: 30px;
        grid-gap: 40px;
    }
    .product-reparations-block{
        grid-gap: 30px;
        margin: 50px 10% 70px 10%;
    }
    .product-review-container h1{
        width: 70%;
    }
}
@media (max-width: 1000px){
    .product-header-image div{
        margin:0 -60px;
        width: 600px;
        height: 630px;
        background-repeat : no-repeat;
        background-size: 100% 100%;
    }
}
@media (max-width: 900px){
    .product-header h1{
        font-size: 40px;
        line-height: 55px;
    }
    .product-repair-block h1{
        font-size: 40px;
        line-height: 55px;
    }
    .product-repair-block p{
        font-size: 20px;
    }
    .product-contact-bock h1{
        font-size: 40px;
        line-height: 55px;
    }
    .product-engagement-container h1{
        font-size: 40px;
    }
    .product-contact-bock p{
        font-size: 21px;
        line-height: 33px;
    }
    .product-reparations-container h1{
        font-size: 40px;
        line-height: 55px;
    }
    .product-review-container h1{
        font-size: 40px;
        line-height: 55px;
    }
    .product-review-scroll-item{
        width: 40% !important;
    }
    .product-review-scroll-block{
        margin-left: -5% !important;
        padding-right: 0px !important;
    }
}
@media (max-width: 820px){
    .product-header-desktop{
        display: none;
    }
    .product-header h1{
        text-align: center;
    }
    .product-header-container{
        display: flex;
        flex-direction: column-reverse;
        background-size: cover;
        justify-content: center;
        align-items: center;
        height: fit-content;
    }
    .product-header-mobile{
        display:inline;
        text-align: center;
    }
    .product-header h3{
        text-align: center;
        font-size: 19px;
    }
    .product-header h1{
        margin-top: 20px
    }
    .product-header-image div{
        width: 420px;
        height: 450px;
        background-repeat : no-repeat;
        background-size: 100% 100%;
        margin-top: -40px;
    }
    .google-rating-container{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .product-engagement-container h1{
        text-align: center;
    }
    .product-repair-container{
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
        background-size: cover;
    }
    .product-repair-container h1 span{
        color: var(--tertiary-1);
    }
    .product-repair-image{
        display: none;
    }
    .product-repair-image-mobile{
        display: flex;
        width:104vw;
        height: 350px;
        margin: 0;
        background-repeat : no-repeat;
        background-size: contain;
        background-position: 100%
    }
    .product-repair-block{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .product-repair-block h1{
        text-align: center;
        margin-left: 20px;
    }
    .product-repair-block p{
        text-align: center;
        width: 100%;
        line-height: 30px;
        width: 80%;
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .product-engagement-block{
        grid-gap: 10px;
    }
    .pe-item {
        gap: 20px
    }
    .product-reparations-block{
        margin: 50px 5% 70px 5%;
    }
}
@media (max-width: 700px){
    .pe-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px
    }
    .pe-item p{
        text-align: center;
    }

    .product-review-scroll-item{
        width: 50% !important;
    }
    .product-review-scroll-block{
        margin-left: 0 !important;
    }

    .product-contact-bock{
        border-radius: 75px 0px 75px 75px;
        width: fit-content;
        padding: 50px;
        margin:0px 20px;
    }
    .product-contact-bock p{
        font-size: 20px;
    }
    .product-reparations-block{
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        margin: 40px 10% 70px 10%;
    }
}
@media (max-width: 600px){
    .product-review-scroll-item{
        width: 70% !important;
    }
    .product-repair-image-mobile{
        width:100vw;
        margin-top: -50px;
        margin-bottom: -60px;
    }
    .product-repair-container{
        gap: 0px;
    }
    .product-repair-block{
        margin-right:0;
    }
}
@media (max-width: 470px){
    .product-review-scroll-item{
        width: 80% !important;
    }
    .product-header h1{
        line-height: 44px;
    }
    .product-repair-block h1{
        font-size: 30px;
        line-height: 40px;
    }
    .product-contact-bock h1{
        font-size: 30px;
        line-height: 40px;
    }
    .product-engagement-container h1{
        font-size: 30px;
    }
    .product-contact-bock p{
        font-size: 20px;
        line-height: 30px;
    }
    .product-reparations-container h1{
        font-size: 30px;
        line-height: 40px;
    }
    .product-review-container h1{
        font-size: 30px;
        line-height: 40px;
        width: 100%;
    }
    .pe-image{
        width: 90%;
        height: 300px;
        background-repeat : no-repeat;
        background-size: contain;
    }
    .pe-item p{
        font-size: 14px;
        width: fit-content;
    }
    .product-reparations-item p{
        font-size: 16px;
    }
    .product-contact-bock{
        padding: 40px;
        margin:0px 15px;
    }
}
@media (max-width: 420px){
    .product-header-image div{
        width: 350px;
        height: 368px;
    }
    .product-review-scroll-item{
        width: 70% !important;
        height: 400px !important;
    }
    .product-contact-bock{
        padding:50px 27px;
        margin:0px 15px;
    }
    .product-contact-bock p{
        font-size: 18px;
    }
}
@media (max-width: 370px){
    .product-google-rating{
        margin-left: -10vw;
    }
}
@media (max-width: 360px){
    .product-header-image div{
        width: 90vw;
        height: 100vw;
        background-size: cover;
        background-position: center;
    }
    .product-contact-bock p{
        font-size: 16px;
    }
}
@media (max-width: 340px){
    .product-review-scroll-item{
        width: 80% !important;
        height: 420px !important;
    }
}