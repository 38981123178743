.contact {
    padding: 20px 20%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 40%;
}
.contact h2{
    padding-bottom: 50px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black-085);
}
.textField{
    background-color: var(--black-0);
    border-radius: 8px;
}
.contact em{
    margin: 3px 5px 20px 10px;
    font-size: 13px;
    color: #f50057;
}
.contact .btn-cancel{
    width: 48%;
    margin-right: 4%;
}
.contact .btn-gradient{
    width: 48%;
}
.contact p{
    padding-top: 40px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.btn-register, .btn-register a {
    align-self: center;
    color: var(--secondary-1);
}

.icon-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 40px;
}
.icon-title h2{
    margin:0%;
    padding-left: 15px;
}

@media (max-width: 1050px){
    .contact {
        padding: 20px 10%;
        width: 60%;
    }
}
@media (max-width: 900px){
    .contact {
        width: 70%;
    }
    .contact h2{
        padding-bottom: 40px;
        font-size: 22px;
    }
    .contact .btn-gradient{
        width: 240px;
    }
    .add-some-padding-top{
        padding-top: 30px;
    }
}
@media (max-width: 500px){
    .textField{
        width: 80vw;
    }
    .captcha{
        width: 0vw;
        transform:scale(0.77); 
        -webkit-transform:scale(0.77); 
        transform-origin:0 0; 
        -webkit-transform-origin:0 0;
    }
    .contact .btn-gradient{
        width: 200px;
    }
}
@media (max-width: 300px){
    .captcha{
        width: 0vw;
        transform:scale(0.60); 
        -webkit-transform:scale(0.60); 
        transform-origin:0 0; 
        -webkit-transform-origin:0 0;
    }
    .contact .btn-gradient{
        width: 90%;
    }
}