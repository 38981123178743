.container-slots{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 30px;
}
.container-day{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-right: 15px;
    margin-bottom: 20px;
}
.container-day h1{   
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.container-time{
    margin-bottom: 5px;  
    border-radius: 4px;
    border: solid 1px var(--black-015);
    background-color: #ffffff;
    display: flex;
    width: 200px;
    height: 40px;
    flex-direction: row;
    margin: 2px;
    cursor: pointer;
}
.container-time-complet{
    margin-bottom: 5px;  
    border-radius: 4px;
    border-radius: 4px;
    border: solid 1px var(--black-010);
    background-color: var(--black-010);
    display: flex;
    width: 200px;
    height: 40px;
    flex-direction: row;
    margin: 2px
}
.container-time-complet p{
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
    opacity: 0.3;
    margin-left: 40px;
    align-self: center;
}
.slot-time{
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
    margin-left: 3px;
}
.slot-time-selected{  
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--balck-100);
    margin-left: 3px;
}


@media (max-width: 700px){
    .container-day{
        margin-right: 10px;
        margin-bottom: 15px;
    }
    .container-day h1{   
        font-size: 18px;
    }
    .container-time{
        width: 150px;
    }
    .container-time-complet{
        width: 150px;
    }
}
@media (max-width: 400px){
    .container-time{
        width: 130px;
    }
    .container-time-complet{
        width: 130px;
    }
}