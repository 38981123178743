.addressUnavailable-container{ 
    margin: 5px;
    width: 100%;
    height: 90vh;
    align-items: center;
}
.addressUnavailable-container div{
    text-align: center;
}
.addressUnavailable-container h1{
    text-align: center;
    color: var(--tertiary-1);
}
.addressUnavailable-container p{
    text-align: center;
    color: var(--tertiary-2);
}
.addressUnavailable-container li{
    margin-bottom: 4px;
    text-align: justify;
    color: var(--tertiary-2);
}
.addressUnavailable-container u{
    cursor: pointer;
    color: var(--secondary-1);
}
.addressUnavailable-container Button{
    background-image: var(--black-010);
    padding: 15px 35px;
    border-radius: 8px;
    border: 0px;
    outline: none;
  }
.addressUnavailable-container Button u {    
    font-family: Roboto;
    font-size: 17px;
    margin-right: 4px;
    margin-bottom: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: none;
    letter-spacing: normal;
    color: var(--black-050);
  }
.addressUnavailable-container Button:active, .demand-navbar a:active {
    transform: scale(0.94);
    /* Scaling button to 0.98 to its original size */
} 
@media (max-width: 500px){
}