@import url("../config/colorPalette.css");
@import url("https://fonts.googleapis.com/css?family=Roboto");

@font-face {
  font-family: "Roboto";
  src: local(Roboto-Bold),
    url(../assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.headerLogo {
  width: 80px;
  background-size: 80px;
  background-repeat: no-repeat;
  background-image: url(../assets/logos/neero_logo_B_png.png);
  position: absolute;
  padding-top: 20px;
}
.headerLogo:active {
  transform: scale(0.98);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Routes-header {
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-container {
  position: relative;
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-areas: "logo nav";
  flex-direction: column;
  justify-content: flex-start;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.navbar-container {
  background-color: #ffffff;
  position: sticky;
  max-height: 70px;
  width: 100vw;
  display: grid;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
}

.menu {
  display: none;
}

ul.header {
  background-color: #ffffff;
  border-radius: 1px;
  box-shadow: 3px 5px 5px var(--black-015);
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 1px;
  padding-left: 13%;
}

ul.header li {
  display: inline;
  list-style-type: none;
}

ul.header li u {
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Roboto;
  text-decoration: none;
}

ul.header li a {
  color: var(--black-050);
  text-decoration: none;
  letter-spacing: normal;
  padding: 10px 15px 10px 15px;
  margin: 5px;
  font-family: "Roboto";
  display: inline-block;
}

/* When navbar button is selected */
.active {
  background-image: linear-gradient(
    257deg,
    var(--secondary-1) 0%,
    var(--main) 100%
  );
  text-align: center;
  transition: 0.4s;
  background-size: 200% auto;
  box-shadow: 0 0 1px #eee;
  border-radius: 8px;
}
.active u {
  color: #fff;
}

.active:hover {
  background-position: center right; /* change the direction of the change here */
  color: #fff;
}
ul.header li a:active {
  color: var(--secondary-1);
}

.navbar-home {
  padding-right: 17%;
}
.navbar-home h1 {
  color: var(--tertiary-1);
  font-stretch: normal;
  font-style: normal;
  font-size: 17px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Roboto;
  text-decoration: none;
}
.navbar-home:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
}

@media (max-width: 1250px) {
  ul.header {
    padding-left: 12%;
  }
  ul.header li a {
    padding: 10px 10px;
  }
}

@media (max-width: 970px) {
  ul.header {
    padding-left: 5%;
  }
  ul.header li a {
    padding: 10px 5px;
  }
}

@media (max-width: 900px) {
  .headerLogo {
    position: relative;
    padding-top: 40px;
    background-position: left bottom;
  }
  ul.header {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (max-width: 400px) {
  .menu-item {
    padding-top: -20px;
  }
}

.menu-nav-home {
  background-color: #ffffff;
  border-radius: 3px;
  width: 100%;
  height: 55px;
  box-shadow: 3px 5px 5px var(--black-015);
}
.menu-nav-home u {
  position: relative;
  color: var(--tertiary-1);
  background-color: #fff;
  font-stretch: normal;
  font-style: normal;
  font-size: 17px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Roboto;
  text-decoration: none;
  margin-top: 15px;
  margin-bottom: 20px;
}
.menu-nav-home u:active {
  transform: scale(0.98);
}

.menu-item u {
  position: relative;
  color: var(--tertiary-1);
  background-color: #fff;
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Roboto;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
.menu-item u:active {
  transform: scale(0.95);
  color: var(--secondary-1);
}

.menu-item u:focus {
  color: var(--secondary-1);
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;
  background-color: #fff;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 5px;
  border-color: #fff;
  transition: color 0.2s;
  border-width: 0px;
  padding: 3px;
}
.bm-item:active {
  border-width: 0px;
}

.bm-burger-button {
  position: absolute;
  width: 32px;
  height: 26px;
  right: 15px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--black-080);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  margin-top: 5px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--black-080);
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
  color: #ffffff;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
