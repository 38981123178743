.cgu-container{ 
    background-color: white;
    width: 100%;
    height:  100%;
    margin-top: 3px;
    margin-bottom: -3px;
}
.cgu-header{
    padding: 1px 0 50px;
    background-color: var(--background);
    margin-bottom: 50px;
}
.cgu-header h1{
    padding: 20px 20%;
    font-family: Roboto;
    text-align: center;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cgu-header h2{
    padding: 0px 20%;
    font-family: Roboto;
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cgu{
    padding: 0 10%;
    margin-bottom: 100px;
}
.cgu h2{
    text-decoration: underline;
    margin-top: 50px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cgu h3{
    margin-top: 27px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cgu h4{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-070);
}

.cgu h4 span{
    margin-right: 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cgu span{
    margin-left: 21px;
}
.cgu h6{
    margin-top: 0px;
    margin-left: 40px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black-070);
}
.cgu h6 span{
    margin-right: 15px;
    font-weight: 600;
    font-size: 18px;
}
.cgu p{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-070);
}
.cgu b{
    font-weight: bold;
}
.cgu em{
    font-weight: bold;
}
.cgu u{
    color: var(--secondary-1);
    cursor: pointer;
}
.cgu-p-h3 b{
    color: var(--secondary-1);
    cursor: pointer;
    text-decoration: underline;
}
.cgu-p-h3{
    margin-bottom: -12px;
    display: flex;
    flex-direction: row
}
.cgu-p-h3 p{
    margin-top: 29px;
    margin-left: 29px;
}

@media (max-width: 700px){
    .cgu{
        padding: 0 7%;
    }
    .cgu-header h1{
        padding: 20px 10%;
        font-size: 41px;
    }
    .cgu-header h2{
        padding: 0px 10%;
        font-size: 22px;
    }
}

@media (max-width: 500px){
    .cgu{
        padding: 0 15px;
    }
    .cgu-header h1{
        padding: 20px 10px;
        font-size: 35px;
    }
    .cgu-header h2{
        font-size: 20px;
    }
}