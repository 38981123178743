
.login-card{
    width: 40%;
    height:fit-content;
    max-height: 700px;
    margin-left: 20%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 #00000014;
    background-color: var(--black-0);
}

.login {
    padding: 50px 12%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.login h2{
    padding-bottom: 50px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black-080);
}
.login em{
    margin: 3px 5px 0px 10px;
    font-size: 13px;
    color: #f50057;
}
.login .btn-cancel{
    width: 48%;
    margin-right: 4%;
}
.login .btn-gradient{
    width: 48%;
}
.login p{
    padding-top: 30px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
}
.btn-register, .btn-register a {
    align-self: center;
    color: var(--secondary-1);
}

.icon-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 40px;
}
.icon-title h2{
    margin:0%;
    padding-left: 15px;
}

@media (max-width: 900px){
    .login-card{
        width: 94%;
        max-height: 600px;
        margin-left: 3%;
        padding-bottom: 40px;
    }
    .login {
        padding: 20px 6%;
    }
    .login h2{
        padding-bottom: 40px;
        font-size: 22px;
    }
    .login .btn-cancel{
        width: 49%;
        margin-right: 2%;
    }
    .login .btn-gradient{
        width: 49%;
    }
    .add-some-padding-top{
        padding-top: 30px;
    }
}
@media (max-width: 500px){

    .login .btn-cancel{
        width: 80%;
        margin-left: 10%;
    }
    .login .btn-gradient{
        width: 80%;
        margin-left: 10%;
    }
    .login-btns{
        display: flex;
        flex-direction: column;
    }
}