:root {
    --main: #416fb4;
    --secondary-1: #00afd0;
    --secondary-2: #9932a0;
    --secondary-3: #ddd6d3;
    --violet: #722CC8;
    --tertiary-1: #6232a0;
    --tertiary-2: #524696;
    --tertiary-3: #F1E4FF;
    --tertiary-4: #FBF7FF;
    --footer-back: #F7F8FD;
    --balck-100: #000000;
    --black-090: #121212;
    --black-87: #18191F;
    --black-085: #202020;
    --black-080: #2d2d2d;
    --black-075: #333A37;
    --black-070: #434343;
    --black-060: #474A57;
    --black-055: #555555;
    --black-050: #7f7f7f;
    --black-010: #f2f2f3;
    --black-030: #b2b2b2;
    --black-025: #a0a0a0;
    --black-015: #e5e5e6;
    --background3: #F3F3F3;
    --background2: #F4F5F7;
    --background: #eeebf3;
    --black-0: #ffffff;
}