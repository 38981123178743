.demande-footer-container{
    margin-top: 100px;
    background-color: #F3F5F6;
    padding: 40px 5%;
}
.demande-footer-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.demande-footer-header-img{
    width: 150px;
    height: 83px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 40px;
    cursor: pointer;
}
.demande-footer-header-img:hover{
    opacity: 0.92;
}
.demande-footer-header-img:active{
    transform: scale(0.99);
}
.demande-footer-header-title-mobile div,
.demande-footer-header-title div{
    position: absolute;
    margin-top: 25px;
    width: 99px;
    height: 79px;
}
.demande-footer-header-title-mobile h2,
.demande-footer-header-title h2{
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #3C3C43;
}
.demande-footer-header-title-mobile p,
.demande-footer-header-title p{
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #3C3C43;
    text-align: left;
    margin-top: -20px;
}
.fixing-rating-margin-2{
    margin-left: -120px;
    margin-top: -20px;
}
.demande-footer-explic{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 35px;
    margin: 50px 0;
}
.demande-footer-expli-icon{
    width: 80px;
    height: 70px;
    margin-left: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.demande-footer-explic h1{
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #524696;
}
.demande-footer-explic p{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7F7F7F;
    text-align: left;
}
.demande-footer-more{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.demande-footer-more u{
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.5px;
    text-decoration-line: underline;
}
.demande-footer-more u:active{ transform: scale(0.98);}
.demande-footer-header-img-mobile{ display: none;}
.demande-footer-header-title-mobile{ display: none;}
@media (max-width: 570px){
    .demande-footer-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .demande-footer-header-img{ display: none;}
    .demande-footer-header-title{ display: none;}
    .demande-footer-header{
        justify-content: center;
    }
    .demande-footer-explic{
        flex-direction: column;
        align-items: center;
        justify-content: center ;
        margin-top: 10px;
        justify-content: space-between;
        gap: 5px;
        margin: 0px;
    }
    .demande-footer-explic-1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center ;
    }
    .demande-footer-expli-icon{
        margin-left: 0px;
    }
    .demande-footer-explic p{
        text-align: center;
        width: 70%;
    }
    .demande-footer-header-img-mobile{
        margin-top: 30px;
        display: flex;
        width: 150px;
        height: 83px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .demande-footer-header-img-mobile:hover{
        opacity: 0.92;
    }
    .demande-footer-header-img-mobile:active{
        transform: scale(0.99);
    }
    .demande-footer-header-title-mobile{
        margin-right: 80px;
        display: flex;
        margin-bottom: 20px;
    }
    .demande-footer-header-title-mobile p{
        margin-top: 80px;
        text-align: center;
        margin-left: -200px;
    }
}