.container-images{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.image-mark-container{
    margin-right: 13px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.image-mark-container p{
    max-width: 70px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--black-050);
}
.image-mark{
    width: 70px;
    height: 70px;
    border: 2px solid var(--tertiary-1);
    border-radius: 10px;
}
.image-mark:active{
    transform: scale(0.94);
}