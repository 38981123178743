@import url("./config/colorPalette.css");
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Poppins');

@font-face {
  font-family: 'Roboto';
  src: local(Roboto-Bold),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}


.headerInfoBar{
  display: flex;
  flex-direction: row;
  width: 100vw;
  background-color: var(--secondary-1);
  align-items: center;
  justify-content: center;
}

p.infoBar {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-right: 40px;
}

.cancelInfoBar {
  position: absolute;
  right: 20px;
}
