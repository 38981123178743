/* CommandesTable */

.cmd-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.title-cmd-list, title-cmd-list2, title-cmd-list3{
    display: flex;
    flex-direction: row;
    padding-left: 40px;
}
.cmd-list n{
    flex: 1;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
}
.cmd-list b{
    flex: 1;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.title-cmd-list n{
    flex: 1;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.cmd-status{
    flex: 1;
    justify-self:flex-end;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    cursor: pointer
}
.title-row-separator{
    height: 1px;
    opacity: 0.3;
    margin-left: 40px;
    margin-right: 40px;
    border: 0px;
    background-color: var(--tertiary-2);
}
.row-separator{
    height: 1px;
    opacity: 0.3;
    margin-right: 40px;
    border: 0px;
    background-color: var(--tertiary-2);
}
.cmd-footer{ 
    margin: 5px;
    text-align: center;
}
.cmd-footer n{ 
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.cmd-footer h{
    padding-left: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--secondary-1);
}
.cmd-list c:active{
    transform: scale(0.97);
}
.cmd-details {
    padding: 30px 10px 20px 10px;
    margin-bottom: auto;
}
.icon-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.icon-title h2{
    margin:0%;
    padding-left: 15px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cmd-details n{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.cmd-details h4{
    margin-left: 30px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
}

@media (max-width: 900px){
    .cmd-list{
        margin-left: -20px;
    }
    .title-cmd-list{
        padding-left: 20px;
    }
    .row-separator{
        margin-left: -20px;
        margin-right: 20px;
    }
    .title-row-separator{
        margin-left: 20px;
        margin-right: 20px;
    }
    .cmd-footer{ 
        padding-left: 10%;
    }
    .cmd-details h4{
        margin-left: 20px;
        font-size: 16px;
    }
}


@media (max-width: 400px){
    .cmd-list{
        margin-left: -30px;
    }
    .title-cmd-list{
        padding-left: 10px;
    }
    .row-separator{
        margin-left: -30px;
        margin-right: 20px;
    }
    .title-row-separator{
        margin-left: 10px;
        margin-right: 20px;
    }
    .cmd-footer{ 
        padding-left: 5%;
    }
    .cmd-list a,.cmd-list b,.cmd-list em{
        padding-right: 2%;
        font-size: 14px;
    }
    .title-cmd-list n{
        margin-right: 4%;
        font-size: 12px;
    }
    .cmd-details h4{
        margin-left: 4%;
        font-size: 15px;
    }
    .cmd-details n{
        font-size: 15px;
    }
}