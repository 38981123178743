
.from-login {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 480px;
}
.from-login h2{ 
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.from-login em{
    margin: 3px 5px 0px 10px;
    font-size: 13px;
    color: #f50057;
}
.from-login .btn-cancel{
    width: 48%;
    margin-right: 4%;
}
.from-login .btn-gradient{
    width: 48%;
}
.from-login p{  
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
}
.from-login h5{    
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
}
.btn-register a {  
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--secondary-1);
}

.icon-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 40px;
}
.icon-title h2{
    margin:0%;
    padding-left: 15px;
}

@media (max-width: 900px){
    .from-login {
        width: 90%;
    }
    .from-login h2{
        padding-bottom: 30px;
        font-size: 22px;
    }
    .from-login .btn-cancel{
        width: 49%;
        margin-right: 2%;
    }
    .from-login .btn-gradient{
        width: 49%;
    }
    .add-some-padding-top{
        padding-top: 30px;
    }
}