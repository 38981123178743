.demand-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.demand-navbar { 
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    height: 70px;
    width: 100vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    justify-content: center;
}
.demand-navbar h1{
    color: var(--tertiary-1);
    font-stretch: normal;
    font-style: normal;
    font-size: 19px;
    font-weight: 500px;
    line-height: normal;
    letter-spacing: normal;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, Roboto ;
    text-decoration: none;
}
.headerLogo2{
    width: 80px;
    background-size: 80px;
    background-repeat : no-repeat;
    background-image: url(../../assets/logos/neero_logo_B_png.png);
    padding-top: 20px;
}
.headerLogo2:active {
    transform: scale(0.98);
}

.demand-navbar a{
    align-self: center;
}
.icon-return{
    color: var(--black-050);
    margin-bottom: -2px;

}
.btn-return{
    position: absolute;
    left: 2%;
    top: 10px;
    background-image: var(--black-010);
    padding: 15px 10px;
    border-radius: 8px;
    border: 0px;
    outline: none;
  }
.btn-return u {    
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: none;
    letter-spacing: normal;
    color: var(--black-050);
  }
.btn-return:active, .demand-navbar a:active {
    transform: scale(0.94);
    /* Scaling button to 0.98 to its original size */
} 

.demand-core{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    height: 100%;
    width: 100vw;
}
.tunnel-cmd {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tunnel-cmd h6{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    margin: 5px;
    letter-spacing: 0.015em;
    color: #999999;
}
.demand-form{
    width: 60%;
}
.demand-resume{
    background-color: var(--background);
    width: 40%;
    min-height: 100vh;
    background-size: 100% 100%;
}
.demande-resume-container{
    padding-bottom: 30px;
    margin:5%
}
.demande-resume-container h1{
    text-align: center;
}
.demande-resume-container h2{
    text-align: center;  
    font-family: Roboto;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--tertiary-2);
}
.demande-resume-container h4{
    text-align: center;  
    font-family: Roboto;
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 600;
    text-align: center;
    color: var(--tertiary-2);
    margin-top: -10px;
}
.demande-resume-container h5{
    text-align: center;  
    font-family: Roboto;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--tertiary-2);
    margin-top: -15px;
    padding-bottom: 20px;
}
.demande-resume-container p{  
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-050);
    margin: -10px 10% 10px 10%;
}
.demande-resume-container h3{
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tertiary-1);
    padding-top: 15px;
}
.resume-box{
    border-radius: 8px;
    border: solid 1px var(--black-015);
    background-color: var(--black-0);      
    margin-bottom: 20px;
}
.resume-box-hedear{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}
.resume-box-hedear div{
    margin-top: 5px;
}
.resume-box-hedear div:active{
    transform: scale(0.90);
}
.resume-box-hedear u{
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-050);
    padding-left: 15px;
    text-decoration: none;
}
.resume-box h4{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    text-align: start;
    padding-left: 15px;
    letter-spacing: normal;
    color: var(--tertiary-1);
    margin-top: -8px;
    margin-bottom: 5px;
}
.resume-box-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 15px;
    border-radius: 6px;
    border: solid 1px var(--black-015);
    background-color: var(--black-0);
}
.resume-box-item h5{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--black-050);
    margin: 10px 0 0 10px;
}
.resume-box-item h6{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--tertiary-1);
    margin: 13px 13px 0px;
}
.resume-box-item b{  
    font-family: Roboto;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: var(--tertiary-1);
    margin: 13px;
}
.resume-box-item-total{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    margin: 12px 15px;
    border-radius: 6px;  
    border: solid 1px var(--black-015);
    background-color: var(--tertiary-2);
}
.resume-box-item-total h5{  
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #ffffff;
    margin: 10px;
}
.resume-box-item-total h6{  
    font-family: Roboto;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--black-0);
    margin: 13px;
}

@media (max-width: 900px){
    .icon-return{
        margin-left: 7px;
        margin-right: 7px;
    }
    .demand-navbar h1 {  
        padding-left: 5%;
        font-size: 17px;
    }
    .btn-return u { 
        display: none
    }
    .demand-form{
        width: 100%;
    }
    .small-tunnel-cmd{
        padding: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(	0, 175, 208, 0.1);
    }
    .small-tunnel-cmd p{
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #2C2C2C;
    }
    .small-tunnel-cmd p span{
        font-weight: 600;
        font-size: 21px;
        margin-left: 15px;
        margin-right: 7px;
    }
    .done-tunnel-cmd{
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--violet);
        border-radius: 25px;
        margin-right: 16px;
    }
    .recap-btn-container{
        background-color: var(--background);
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .recap-btn-container button{
        display: flex;
        align-items: center;
        background-color: 'transparent';
        cursor: pointer;
    }
    .recap-btn-container p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        margin-left: 9px;
        color: #00AFD0;
    }
    .small-demand-core{
        background-color: white;
        margin-bottom: 20px;
    }
    .demand-resume{
        background-color: var(--background);
        margin-top: -5.6%;
        margin-bottom: 10px;
        width: 100%;
    }
    .demande-resume-container{
        padding-top: 20px;
    }
    .resume-box-item b{  
        font-size: 16px;
        font-weight: 500;
        margin: 10px;
    }.resume-box-item h5{
        font-family: Roboto;
        font-size: 16px;
    }
    .resume-box-item h6{
        font-size: 16px;
        font-weight: 500;
        margin: 13px;
    }
}
