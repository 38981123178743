.footerV2-container{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-image: linear-gradient(to bottom, var(--footer-back), var(--footer-back));
    padding: 70px 0;
}
.footeV2-block{
    margin-left: 10%;
    margin-right: 20px;
}
.footeV2-block h1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.5px;
    color: #2C2C2C;
    flex-wrap: wrap;
    text-align: left;
}
.footeV2-block p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #757575;
    opacity: 0.75;
    text-align: left;
}
.footeV2-block h6{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #2C2C2C;
    margin: 0;
}
.footerV2-address{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px
}
.footerV2-cities{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.footerV2-city{
    background: rgba(114, 44, 200, 0.1);
    border-radius: 20px;
    padding: 10px 13px;
}
.footerV2-city h3{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #722CC8;
    margin: 0;
}
.footerV2-city span{
    margin-left: 7px;
}
.footerV2-map-container{
    margin-right: 5%;
}
.footerV2-map{
    width: 435px;
    height: 450px;
    background-size: 100% 100%;
    background-repeat : no-repeat;
}
@media (max-width: 1200px){
    .footerV2-map-container{
        margin-right: 70px;
    }
    .footeV2-block{
        margin-left: 12%;
        margin-right: 15px;
    }
    .footerV2-map{
        width: 380px;
        height: 400px;
    }
}

@media (max-width: 1102px){
    .footeV2-block h1{
        font-size: 40px;
    }
    .footeV2-block p{
        font-size: 17px;
    }
    .footeV2-block h6{
        font-size: 13px;
    }
    .footerV2-city h3{
        font-size: 13px;
    }    
    .footerV2-map-container{
        margin-right: 0;
    }
    .footeV2-block{
        margin-left: 0px;
        margin-right: 0;
    }
    .footerV2-container{
        flex-wrap: wrap;
    }

    .footerV2-map{
        width: 435px;
        height: 450px;
    }
}

@media (max-width: 900px){
    .footerV2-map-container{
        margin: 20px 10%;
    }
    .footeV2-block{
        margin: 0 10%;
    }
    .footerV2-address{
        flex-direction: column;
        gap: 12px;
    }
    .footerV2-cities{
        margin: 0 10%;
    }
}
@media (max-width: 600px){
    .footerV2-map-container{
        margin: 20px 30px;
    }
    .footeV2-block{
        margin: 0 30px;
    }
    .footerV2-cities{
        margin: 0 30px;
    }
    .footerV2-map{
        width: 380px;
        height: 400px;
    }
}

@media (max-width: 500px){
    .footerV2-container{
        padding: 20px 0;
    }
    .footerV2-map{
        width: 330px;
        height: 350px;
    }
    .footeV2-block h1{
        font-size: 32px;
        line-height: 40px;
    }
    .footeV2-block p{
        font-size: 15px;
        line-height: 22px;
    }
    .footeV2-block h6{
        font-size: 12px;
    }
    .footerV2-city h3{
        font-size: 12px;
    }  
    .footerV2-map-container{
        margin: 20px 20px;
    }
    .footeV2-block{
        margin: 0 20px;
    }
    .footerV2-cities{
        margin: 0 20px;
    } 
}

@media (max-width: 380px){
    .footerV2-map{
        width: 300px;
        height: 320px;
    }
}
@media (max-width: 350px){
    .footerV2-map{
        width: 270px;
        height: 285px;
    }
}