.repair-title{
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.repair-title h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 100px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: var(--black-085);
    margin-bottom: 0;
}
.repair-title p{
    font-family: Roboto;
    font-size: 28px;
    line-height: 33px;
    color: var(--black-075);
    text-align: center;
    margin-bottom: 15px;
}
.repair-main-image{
    margin-top: 40px;
}
.repair-main-image div{
    background-size: contain;
    background-repeat : no-repeat;
    height: 50vw;
}
.repair-first-block{
    padding: 20px 12vw;
}
.repair-first-block h2, .repair-second-block h2{
    font-family: Roboto;
    font-weight: 600;
    font-size: 46px;
    line-height: 56px;
    color: var(--black-085);
    text-align: left;
}
.repair-first-block p, .repair-second-block p{
    font-family: Roboto;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: var( --black-050);
    margin-top: -10px;
    text-align: left;
}
.repair-engage{
    margin: 40px 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.repair-engage-item{
    width: 240px;
    height: 220px;
    background: var(--tertiary-4);
    border: 0.5px solid rgba(128, 65, 205, 0.1);
    box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    margin: 10px;
}
.repair-engage-item p{
    font-family: Roboto;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: var(--black-055);
    text-align: center;
}
.repair-engage-item div{
    height: 110px;
    width: 110px;
    transform: scale(0.97);
}
.repair-second-block{
    margin-top: 40px;
    padding: 20px 12vw;
    background: #fbf9fd;
}
.repair-sb{
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.repair-sb img{
    background-size: contain;
    background-repeat : no-repeat;
    height: 33vw;
}
.repair-sb-img-1{
    margin-right: 6vw;
}
.repair-sb-img-2{
    margin-left: 6vw;
}
.repair-sb-s{
    margin-top: 120px;
}
.repair-sb-s h2, p{
    text-align: center;
}
.repair-interv{
    margin: 60px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.repair-interv-item div{
    height: 196px;
    width: 164px;
    transform: scale(0.97);
}
.repair-interv-item h4{
    font-family: Roboto;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--main)
}
.rapair-last-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rapair-last-block small{
    margin-top: 60px;
    font-family: Roboto;
    color: #70757A;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap; 
}
.rapair-last-block small:active{
    transform: scale(0.98);
}
.review-scroll-block{
    background: #fbf9fd;
    padding-bottom: 90px;
}
.review-scroll{
    width: 88vw;
    margin-left: 12vw;
	overflow-x: auto;
	overflow-y: hidden;
    white-space: nowrap; 
    font-size: 0;
    padding-bottom: 50px;
}
.review-scroll-item{
    display: inline-block;
	width: 70%;
	height: 250px;
    margin-right: 50px;
    background: #FFFFFF;
    box-shadow: 0px 2px 80px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 40px;
}
.review-scroll-item div{
    display:inline-flex;
    flex-direction: column;
    align-items: flex-start;
}
.review-scroll-item h3{
    white-space:normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 7px;
}
.review-scroll-item p{
    font-weight: 300;
    font-size: 17px;
    line-height: 140%;
    white-space:normal;
    color: var(--black-080);
    opacity: 0.9;
    text-align: justify;
    margin-top: 20px;
}
.review-scroll-item small{
    font-size: 17px;
    line-height: 140%;
    white-space:normal;
    color: rgba(60, 60, 67, 0.5);
}
.rating-container div{
    margin-right: 5px;
    height: 28px;
    width: 28px;
}
/* width */
.review-scroll::-webkit-scrollbar {
    height: 10px;
}
/* Track */
.review-scroll::-webkit-scrollbar-track {
    background: #F2F2F7;
    border-radius: 16px;
    margin-right: 12vw;
}
/* Handle */
.review-scroll::-webkit-scrollbar-thumb {
    background: rgba(114,44,200, 0.7);
    border-radius: 16px;
}
/* Handle on hover */
.review-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(114,44,200, 1);
}
.repair-footer{
    margin: -16px 0 0;
    padding: 20px 12vw;
    background-color: var(--tertiary-3);
}
.repair-footer p{
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.02em;
    color: #8041CD;
}
.repair-sb-img-2-mobile{
    display: none;
}
@media (max-width: 1200px){
    .repair-first-block{
        padding: 20px 10vw;
    }
    .repair-second-block{
        padding: 20px 10vw;
    }
    .review-scroll{
        width: 90vw;
        margin-left: 10vw;
    }
    .review-scroll::-webkit-scrollbar-track {
        margin-right: 10vw;
    }
    .repair-footer{
        padding: 26px 10vw;
    }
}
@media (max-width: 1000px){
    .repair-first-block{
        padding: 20px 5vw;
    }
    .repair-second-block{
        padding: 20px 5vw;
    }
    .review-scroll{
        width: 95vw;
        margin-left: 5vw;
    }
    .review-scroll::-webkit-scrollbar-track {
        margin-right: 5vw;
    }
    .repair-footer{
        padding: 26px 5vw;
    }
    .repair-title h1{
        font-size: 60px;
        line-height: 90px;
    }
    .repair-title p{
        font-size: 25px;
        line-height: 30px;
    }
    .repair-first-block h2, .repair-second-block h2{
        font-size: 41px;
        line-height: 53px;
    }
    .repair-first-block p, .repair-second-block p{
        font-size: 22px;
        line-height: 37px;
    }
    .repair-engage-item{
        width: 210px;
        height: 200px;
    }
    .repair-engage-item p{
        font-size: 22px;
    }
    .repair-engage-item div{
        transform: scale(0.85);
    }
    .repair-sb img{
        height: 40vw;
    }
    .repair-sb-img-1{
        margin-right: 40px;
    }
    .repair-sb-img-2{
        margin-left: 30px;
    }
    .repair-sb-s{
        margin-top: 90px;
    }
    .repair-interv-item div{
        transform: scale(0.90);
    }
    .repair-interv-item h4{
        font-size: 22px;
    }
}
@media (max-width: 810px){
    .repair-first-block{
        padding: 20px 3vw;
    }
    .repair-second-block{
        padding: 20px 3vw;
    }
    .review-scroll{
        width: 97vw;
        margin-left: 3vw;
    }
    .review-scroll::-webkit-scrollbar-track {
        margin-right: 3vw;
    }
    .repair-footer{
        padding: 26px 3vw;
    }
    .repair-title h1{
        font-size: 55px;
        line-height: 85px;
    }
    .repair-title p{
        font-size: 23px;
        line-height: 30px;
    }
    .repair-first-block h2, .repair-second-block h2{
        font-size: 35px;
        line-height: 51px;
    }
    .repair-first-block p, .repair-second-block p{
        font-size: 19px;
        line-height: 36px;
    }
    .repair-engage-item{
        width: 180px;
        height: 180px;
        padding: 2px;
    }
    .repair-engage-item p{
        font-size: 19px;
    }
    .repair-engage-item div{
        transform: scale(0.62);
    }
    .repair-sb img{
        height: 45vw;
    }
    .repair-sb-img-1{
        margin-right: 30px;
    }
    .repair-sb-img-2{
        margin-left: 20px;
    }
    .repair-sb-s{
        margin-top: 80px;
    }
    .repair-interv{
        justify-content: center;
    }
    .repair-interv-item div{
        transform: scale(0.80);
    }
    .repair-interv-item h4{
        font-size: 20px;
        margin-top: 0;
    }
    .review-scroll-item{
        width: 80%;
        height: 270px;
        margin-right: 40px;
        padding: 30px;
    }
    .review-scroll-item h3{
        font-size: 21px;
        line-height: 100%;
        margin-bottom: 6px;
    }
    .review-scroll-item p{
        font-size: 16px;
        line-height: 130%;
        margin-top: 18px;
    }
    .review-scroll-item small{
        font-size: 16px;
        line-height: 130%;
    }
    .rating-container div{
        transform: scale(0.98);
    }
}

@media (max-width: 650px){
    .repair-title h1{
        font-weight: 600;
        font-size: 36px;
        line-height: 45px;
    }
    .repair-title p{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0 ;
    }
    .repair-main-image{
        margin-top: 20px;
        margin-bottom: -20px;
    }
    .repair-main-image div{
        background-size: cover;
        background-position: center center;
        background-repeat : no-repeat;
        height: 50vw;
    }
    .repair-first-block h2, .repair-second-block h2{
        font-size: 36px;
        line-height: 50px;
        text-align: center;
    }
    .repair-first-block p, .repair-second-block p{
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }
    .repair-engage-item{
        width: 175;
        height: 160px;
        padding: 2px;
        margin: 3px;
    }
    .repair-engage-item p{
        font-size: 16px;
        line-height: 19px;
    }
    .repair-engage-item div{
        transform: scale(0.62);
    }
    .repair-sb{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .repair-sb div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .repair-sb img{
        height: 80vw;
    }
    .repair-sb-img-1{
        margin-right: 0px;
    }
    .repair-sb-img-2{
        display: none;
    }
    .repair-sb-img-2-mobile{
        display: block;
    }
    .repair-sb-s{
        margin-top: 60px;
    }
    .rapair-last-block{
        display: flex;
        flex-direction: column;
    }
    .rapair-last-block small{
        margin-top: -10px;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
    }
    .rapair-last-block h2{
        text-align: left;
    }
    .repair-interv-item div{
        transform: scale(0.70);
    }
    .repair-interv-item h4{
        font-size: 18px;
        margin-top: 0;
    }
    .review-scroll-item{
        width: 70%;
        height: 300px;
        margin-right: 30px;
        padding: 30px;
    }
    .review-scroll-item h3{
        font-size: 21px;
        line-height: 100%;
        margin-bottom: 6px;
    }
    .review-scroll-item p{
        font-size: 17px;
    }
    .review-scroll-item small{
        font-size: 17px;
    }
    .repair-footer{
        display: none;
    }

}
@media (max-width: 550px){
    .review-scroll-item{
        width: 70%;
        height: 350px;
        padding: 20px;
    }
    .repair-main-image div{
        height: 55vw;
    }
}
@media (max-width: 500px){
    .review-scroll-item{
        width: 70%;
        height: 350px;
        padding: 20px;
    }
}
@media (max-width: 410px){
    .review-scroll-item{
        width: 75%;
        height: 400px;
        padding: 15px;
    }
    .repair-main-image div{
        height: 60vw;
    }
}
@media (max-width: 320px){
    .review-scroll-item{
        width: 75%;
        height: 450px;
        padding: 10px;
    }
}